import React, { useEffect, useState } from "react";
import tune from "../../../assets/svg/tune.svg";
import SeasonalItemTable from "../../layouts/SeasonalItemTable";
import AddSeasonalItem from "../../layouts/modals/AddSeasonalItem";
import { get } from "../../../utility/fetch";

const SeasonalItemBonus = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [seasonalBonus, setSeasonalBonus] = useState([]);
  const [deductionSource, setDeductionSource] = useState([]);

  const getDeductionSources = async () => {
    try {
      const res = await get("/DeductionSource");
      setDeductionSource(
        res.data?.map((item) => ({
          label: item.description,
          value: item.id,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getDeductions = async () => {
    try {
      const res = await get("/Bonus/Approved");
      setSeasonalBonus(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  const data = [
    {
      deductionItems: "13 Month Bonus",
      deductionPercentage: "7.5%",
      calculatedFrom: "Basic Monthly Salary",
      initiatedBy: "10.10.2023",
      updated: "10.10.2023",
    },
  ];

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getDeductionSources();
    getDeductions();
  }, []);

  return (
    <div className="w-100">
      <div className="flex space-between">
        <div className="flex flex-v-center m-t-20">
          <img className="m-r-10" src={tune} alt="" />
          <span className="dash-text">Seasonal Items/Bonus</span>
        </div>

        <a href="/payroll/settings/seasonal-item/initiate-add-request/0">
          <button className="btn p-15">+ Add a seasonal Item</button>
        </a>
      </div>
      <div className="m-t-20">
        <SeasonalItemTable
          tableData={seasonalBonus}
          fetchData={getDeductions}
          deductionSource={deductionSource}
        />
      </div>
      {isModalOpen && (
        <AddSeasonalItem
          closeModal={closeModal}
          deductionSource={deductionSource}
          fetchData={getDeductions}
        />
      )}
    </div>
  );
};

export default SeasonalItemBonus;
