import { createContext, useState } from 'react';

// Context  For Storing User Information
const MyContext = createContext();


// Sample stafpayroll

// {
//     employeeId: 0,
//     baseSalary: 0,
//     totalAllowances: 0,
//     tax: 0,
//     pensionContribution: 0,
//     otherDeductions: 0,
//     netPay: 0,
//     notes: ""
// }

const MyProvider = ({ children }) => {
    const [payrollData, setPayrollData] = useState({
        mdaId: 0,
        psId: 0,
        dfaId: 0,
        title: "",
        month: null,
        year: null,
        comment: "",
        totalGrossPayment: 0,
        totalDeductions: 0,
        overallNetPayment: 0,
        thirteenthMonth:"",
        cooperativeDeductions: "",
        otherDeductions:0,
        staffPayroll: [
           
        ]
    });

    const [tableHeaders, setTableHeaders] = useState([
        "Select",
        "Staff ID",
        "Staff Name",
        "Salary Structure",
        "Grade/Step",
        "Base Salary",
        "Total Allowances",
        "Tax",
        "Pension Contribution",
        "Net Pay",
        "Notes",
      ])

    const [isApprover, setIsApprover] = useState(false);

    const [isEdit, setIsEdit] = useState(false);

    const [postComp, setPostComp] = useState(false);
    const [additionalColumns, setAdditionalColumns] = useState([]);
    const [OtherDeductionValues, setOtherDeductionValues] = useState([])
    const [additionalColumnsOptions, setAdditionalColumnOptions] = useState([]);
    const [contextColumnOptions, setContextColumnOptions] = useState([]);

    return (
        <MyContext.Provider value={{ payrollData,contextColumnOptions, setContextColumnOptions, additionalColumnsOptions, setAdditionalColumnOptions, OtherDeductionValues, setOtherDeductionValues,  tableHeaders, additionalColumns, setAdditionalColumns, setTableHeaders, setPayrollData, postComp, setPostComp, isApprover, setIsApprover,isEdit, setIsEdit }}>
            {children}
        </MyContext.Provider>
    );
};

export { MyProvider, MyContext };
