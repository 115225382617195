import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Homepage from './components/home';
import ProtectedRoute from './routes/ProtectedRoute';
import "./assets/css/index.less";
import { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';
import UserPortal from './components/UserPortal';
import { MyProvider } from './contexts';
import helpImage from "./assets/images/helpImage.jpg";



function App() {
  const history = createBrowserHistory();

  return (
    <MyProvider>
   
      <Router history={history}>
        <div>
          <Switch>
            <Route path="/" exact component={Homepage} />

            <ProtectedRoute path="/payroll/" component={UserPortal} />
            <Route render={() => <h1>Error 404. Page not found.</h1>} />
          </Switch>
          <Toaster />
        </div>
      </Router>
      {/* <a style={{position:"absolute", bottom: 30, right: 30, zIndex:100 }} target='_blank' href='https://greenzonetechnologies.atlassian.net/servicedesk/customer/portals'><img src={helpImage} /></a> */}
    </MyProvider>
  );
}

export default App;
