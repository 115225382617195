import { useEffect, useState } from "react";
import tune from "../../../assets/svg/tune.svg";
import SalaryTemplateTable from "../../layouts/SalaryTemplateTable";
import UpdateSalaryTemplate from "../../layouts/modals/UpdateSalaryTemplate";
import AddSalaryTemplate from "../../layouts/modals/AddSalaryTemplate";
import { get } from "../../../utility/fetch";

const SalaryTemplateManager = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [group, setGroup] = useState([]);
  const [salaryStructures, setSalaryStructures] = useState([]);
  const [structureTypes, setStructureTypes] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedStructureType, setSelectedStructureType] = useState(null);

  const mdaList = [
    "Ministry of Education",
    "Ministry of Finance",
    "Ministry of Health",
    "Ministry of Labour",
    "Ministry of Industries",
    "Ministry of Sports",
  ];
  const [grades, setGrades] = useState([]);

  const [selectedGrade, setSelectedGrade] = useState("");

  function getAllGrades(res) {
    let uniqueGrades = [];
    res.forEach((item) => {
      if (!uniqueGrades.includes(item.level)) {
        uniqueGrades.push(item.level);
      }
    });

    uniqueGrades.sort((a, b) =>
      a.localeCompare(b, undefined, { numeric: true })
    );
    setGrades(uniqueGrades);
    filterGroups(selectedGrade);
  }
  const toggleStructureType = (structureTypeId) => {
    setSelectedStructureType((prevSelected) =>
      prevSelected === structureTypeId ? null : structureTypeId
    );
  };

  useEffect(() => {
    filterGroups(selectedGrade);
  }, [selectedStructureType]);

  // const getLastUpdatedBy = async () => {
  //   try {
  //     const res = await get("/staffById/");
  //     console.log(res);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  const filterGroups = async (grade) => {
    const filteredGroups = salaryStructures.filter(
      (group) =>
        group.level === grade &&
        (selectedStructureType === null ||
          group.typeId === selectedStructureType)
    );
    setSelectedGrade(grade);
    setGroup(filteredGroups);
  };
  const getStructureTypes = async () => {
    try {
      const res = await get("/StructureType");
      setStructureTypes(
        res.data?.map((item) => ({
          label: item.structureType,
          value: item.id,
        }))
      );
    } catch (err) {}
  };

  const get1Salarytructure = async (id) => {
    try {
      const res = await get(`/SalaryStructure/${id}`);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  const get1StructureType = async (id) => {
    try {
      const res = await get(`/StructureType/${id}`);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openModal2 = () => {
    setIsModal2Open(true);
  };

  const closeModal2 = () => {
    setIsModal2Open(false);
  };
  const fetchDataAndFilter = async () => {
    try {
      const res = await get("/SalaryStructure/Approved");
      setSalaryStructures(res.data);
      getAllGrades(res.data);
    } catch (err) {
      console.log(err);
    }

    getStructureTypes();
  };

  useEffect(() => {
    fetchDataAndFilter();
  }, []);

  useEffect(() => {
    // Check if grade is not empty and group is empty
    if (grades[0] && group.length === 0) {
      filterGroups(grades[0]);
    }
  }, [grades, group.length]);

  return (
    <div className="w-100">
      <div className="flex flex-v-center m-t-20">
        <img className="m-r-10" src={tune} alt="" />
        <span className="dash-text">Salary Template Manager</span>
      </div>

      <div className="flex space-between p-20 m-b-20">
        <div className="flex">
          {structureTypes.map((tag, index) => (
            <div
              key={index}
              className={`m-r-20 pointer
                drop-shadow-sm ${
                  selectedStructureType === tag.value
                    ? "selected-tiny-card"
                    : "tiny-card"
                }`}
              onClick={() => {
                toggleStructureType(tag.value);
              }}
            >
              <p>{tag.label}</p>
            </div>
          ))}
        </div>
        <div className="flex space-between">
          {selectedItem ? (
            <a
              href={`/payroll/settings/salary-template-manager/initiate-add-request/${selectedItem?.id}`}
            >
              <button
                type="button"
                className="pointer p-15 add-button drop-shadow-sm m-r-10"
                disabled={!selectedItem}
              >
                Update Salary Template
              </button>
            </a>
          ) : (
            <button
              type="button"
              className="pointer p-15 add-button drop-shadow-sm m-r-10"
              disabled={!selectedItem}
            >
              Update Salary Template
            </button>
          )}

          <a href="/payroll/settings/salary-template-manager/initiate-add-request/0">
            <button type="button" className="pointer p-15 btn drop-shadow-sm">
              + Add Salary Template
            </button>
          </a>
        </div>
      </div>
      <div className=" w-100 p-20 white-bg bord flex space-between">
        <div className="flex bold-text">
          <p>Edo State</p>
          <p className="text-red">&nbsp; Civil Service &nbsp; </p>
          <p>Salary Template (N40,000) Minimum Wage, &nbsp;</p>
          <p className="text-red">{new Date().getFullYear()}</p>
        </div>
        {/* <div className="flex">
          <p>Last updated by</p>
          <p className="text-red"></p>
          <p className="text-red">{new Date().getFullYear()}</p>
        </div> */}
      </div>

      <div className="m-t-20 flex">
        <div className="m-r-10">
          {grades.map((grade, index) => (
            <div
              key={index}
              className={` m-b-10 ${
                selectedGrade === grade ? "selected-grade" : ""
              }`}
            >
              <button
                className={`rounded-btn-left pointer ${
                  selectedGrade === grade ? "green-bg text-white" : "white-bg"
                }`}
                onClick={() => filterGroups(grade)}
              >
                Grade {grade}
              </button>
            </div>
          ))}
        </div>
        {group && (
          <SalaryTemplateTable
            tableData={group}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        )}
      </div>
      <div className="m-t-20">
        <span className="dash-text">Assigned MDA/Agency</span>
        <div className="m-t-20">
          {mdaList.map((mda, index) => (
            <div className="m-b-10">
              <input type="checkbox" />
              {mda}
            </div>
          ))}
        </div>
      </div>
      {isModalOpen && (
        <UpdateSalaryTemplate
          closeModal={closeModal}
          types={structureTypes}
          data={selectedItem}
          fetchData={fetchDataAndFilter}
        />
      )}
      {isModal2Open && (
        <AddSalaryTemplate
          closeModal={closeModal2}
          fetchData={fetchDataAndFilter}
          types={structureTypes}
        />
      )}
    </div>
  );
};

export default SalaryTemplateManager;
