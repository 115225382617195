import xls from "../../assets/svg/xls.svg";
import preview from "../../assets/svg/preview.svg";
import tune from "../../assets/svg/tune.svg";
import TextInput from "../inputs/TextInput";
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import ComputationTable from "../layouts/ComputationTable";
import ComputationModal from "../layouts/ComputationModal";
import { useContext, useEffect, useState } from "react";
import { MyContext } from "../../contexts";
import { get } from "../../utility/fetch";
import { Link } from "react-router-dom";
import { months, stripCommas, statutoryOptions, mergeArraysWithUniqueIds, removeDuplicates } from "../../utility/general";
import toast from "react-hot-toast";
import UploadButton from "../layouts/UploadButton";
import { AiOutlinePaperClip } from "react-icons/ai";

const PayrollComputationSheet = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalIndex, setModalIndex] = useState(false);
  const { payrollData, additionalColumns, setContextColumnOptions, contextColumnOptions, setAdditionalColumnOptions, additionalColumnsOptions, OtherDeductionValues, setOtherDeductionValues, tableHeaders, setTableHeaders, postComp, setPostComp, setPayrollData, isEdit } = useContext(MyContext);
  const [showOthers, setShowOthers] = useState(false);
  const [userName, setUserName] = useState("");
  const [show13th, setShow13th] = useState(false);
  const [showCoop, setShowCoop] = useState(false);
  const [tableId] = useState("table-to-xls")
  const [bulkUploadArray, setBulkUploadArray] = useState([]);


  const [updateValue, setUpdateValue] = useState("");
  const [updateColumn, setUpdateColumn] = useState("")
  const [allOn, setAllOn] = useState(false);
  const [approvedDeductions, setApprovedDeductions] = useState([])
  const [staffDeductions, setStaffDeductions] = useState([
    {
      id: 0,
      deductionItem: "",
      userId: 0,
      deductionId: 0,
      amount: 0
    }
  ])

  const [commentUpdateValue, setCommentUpdateValue] = useState("");



  const uploadValues = () => {
    console.log(updateColumn)
    console.log(updateValue)

    if (!updateColumn || !updateValue) {
      toast.error("Please select a column and value to update.")
      return;
    }

    if (bulkUploadArray.length < 1) {
      toast.error("Please select a staff to update deductions.")
      return;
    }

    if (
      updateColumn?.value === "ltg" ||
      updateColumn?.value === "thirteenthMonth" ||
      updateColumn?.value === "arrears" ||
      updateColumn?.value === "cooperativeDeductions" ||
      updateColumn?.value === "otherDeductions" ||
      updateColumn?.value === "healthInsuranceDeductions" ||
      updateColumn?.value === "pensionContribution" ||
      updateColumn?.value === "tax" ||
      updateColumn?.value === "otherBonus"
    ){

      let payR = payrollData?.staffPayroll?.map((payroll) => {
        if (bulkUploadArray.includes(payroll.staffId)) {
          return {
            ...payroll,
            [updateColumn?.value]: parseFloat(updateValue)
          }
        }
        else{
          return payroll
        }
      });

      setPayrollData((prev) => ({ ...prev, staffPayroll: payR }))
      // updateNetPay();
    }

    else {
      let payR = payrollData?.staffPayroll?.map((payroll) => {

        let temp = payroll.staffDeductions.find((item) => item.deductionId === updateColumn.deductionId);

        if (bulkUploadArray.includes(payroll.staffId)) {
          if (temp) {


            // let updated = payroll.staffDeductions.filter((item) => item.deductionId !== updateColumn.deductionId)

            // updated.push({
            //   ...temp,
            //   "amount": Number(updateValue)
            // })
            // payroll.staffDeductions = updated;
            return {
              ...payroll,
              staffDeductions: payroll?.staffDeductions?.map((item)=>{
                if(item.deductionId === updateColumn.deductionId){
                  return {...item, "amount": parseFloat(updateValue) }
                }
                return item;
              })
            }

          }
          else {
            payroll.staffDeductions.push({
              "deductionItem": updateColumn?.label,
              "userId": payroll?.employeeId,
              "deductionId": updateColumn?.deductionId,
              "amount": Number(updateValue)
            })
          }

        }
        else {
          if (temp) {

          }
          else {
            payroll.staffDeductions.push({
              "deductionItem": updateColumn?.label,
              "userId": payroll?.employeeId,
              "deductionId": updateColumn?.deductionId,
              "amount": 0
            })
          }

        }
        return payroll
      });
      setPayrollData((prev) => ({ ...prev, staffPayroll: payR }))
      // updateNetPay();
    }


  }


  const uploadCommentValues = () => {

    // if (!commentUpdateValue) {
    //   toast.error("Please enter a comment to update.")
    //   return;
    // }

    if (bulkUploadArray.length < 1) {
      toast.error("Please select a staff to update comments.")
      return;
    }

    let payR = payrollData?.staffPayroll?.map((payroll) => {
      if (bulkUploadArray.includes(payroll.staffId)) {
        payroll.notes = commentUpdateValue;
      }
      return payroll
    });
    setPayrollData((prev) => ({ ...prev, staffPayroll: payR, staffPayrollResponse: payR }))
    // updateNetPay();



  }

  const recImagg = (value) => {
    console.log(value);

    let docs = payrollData?.uploads;

    docs = [...docs, value?.docPath]

    console.log({
      ...payrollData, uploads: docs
    })
    setPayrollData((payrollData) => ({
      ...payrollData, uploads: docs
    }));

    // setUploads([...uploads, {
    //     docName: value?.docTitle,
    //     docUrl: value?.docPath,
    //     attachmentTypeId: data?.id || 6
    // }])
  }


  console.log(payrollData)


  const updateNetPay = () => {

    let payR = payrollData?.staffPayroll?.map((item) => ({
      ...item, netPay: parseFloat((
        parseFloat(Number(item?.baseSalary || 0) +
          Number(item?.totalAllowances || 0) +
          Number(item?.arrears || 0) +
          Number(item?.otherBonus || 0) +
          Number(item?.ltg || 0) +
          Number(item?.thirteenthMonth || 0) || 0) -
        (Number(item?.tax || 0) +
          Number(item?.pensionContribution || 0) +
          Number(item?.otherDeductions || 0) +
          Number(item?.healthInsuranceDeductions || 0) +
          Number(item?.cooperativeDeductions || 0))
      ) + parseFloat(item?.bonus?.reduce((acc, item) => acc + item?.amount, 0)) -
        parseFloat(item?.staffDeductions?.reduce((acc, item) => acc + item?.amount, 0))).toFixed(2),
    }))
    setPayrollData((prev) => ({ ...prev, staffPayroll: payR }))
  }

  const handleSelectAll = () => {
    if (allOn) {
      setAllOn(false)
      setBulkUploadArray([])
    }
    else {
      setAllOn(true)
      setBulkUploadArray(payrollData?.staffPayroll?.map((payroll) => payroll.staffId))
    }
  }


  const closeModal = () => {
    setShowModal(false);
  };

  const handleAdditionalColumnsOptions = (value) => {
    console.log(value)
    setUpdateColumn(value)
  }

  const handleUpdateChange = (e) => {
    setUpdateValue(e.target.value);
  }

  const handleCommentUpdateChange = (e) => {
    setCommentUpdateValue(e.target.value);
  }

  const handleAdditional = (column, value) => {
    if (additionalColumns.includes(column)) {
      setAdditionalColumnOptions(prevColumnOptions => prevColumnOptions.filter(val => val.value !== column))
    }
    else {
      setAdditionalColumnOptions(value?.map((item) => ({
        value: item?.value,
        label: item?.label,
        deductionId: item?.all?.deductionId || item?.label,
      })))

      let newArra = mergeArraysWithUniqueIds(contextColumnOptions, value?.map((item) => ({
        value: item?.value,
        label: item?.label,
        deductionId: item?.all?.deductionId || item?.label,
      })), "value")
      setContextColumnOptions(newArra)
      
    }

    if (tableHeaders.includes(column)) {
      setTableHeaders(prevTableHeaders => prevTableHeaders.filter(header => header !== column))
    }
    else {
      let temp = tableHeaders
      temp.splice(temp.length - 2, 0, column)
      setTableHeaders(temp)

    }
  }



  const goToPreview = () => {
    for (let i = 0; i < payrollData?.staffPayroll?.length; i++) {
      // if (payrollData?.staffPayroll[i]?.healthInsuranceDeductions < 1) {
      //   toast.error("At least one of your HEALTH INSURANCE DEDUCTIONS is 0, Please update.");
      //   return;
      // }

      // if (payrollData?.staffPayroll[i]?.baseSalary < 1) {
      //   toast.error("At least one of the values of employee BASE SALARY is 0, Please update.");
      //   return;
      // }

      // if (payrollData?.staffPayroll[i]?.tax < 1) {
      //   toast.error("At least one of the values of employee TAX is 0, Please update.");
      //   return;
      // }

      // if (payrollData?.staffPayroll[i]?.pensionContribution < 1) {
      //   toast.error("At least one of the values of employee PENSION CONTRIBUTION 0, Please update.");
      //   return;
      // }

      if (payrollData?.staffPayroll[i]?.otherDeductions || payrollData?.staffPayroll[i].thirteenthMonth || payrollData?.staffPayroll[i].cooperativeDeductions || payrollData?.staffPayroll[i].arrears || payrollData?.staffPayroll[i].healthInsuranceDeductions) {
        // if (!payrollData?.staffPayroll[i].notes) {
        //   toast.error("Please make sure all deductions have a note.");
        //   return;
        // }
        // console.log(payrollData?.staffPayroll[i]?.healthInsuranceDeductions < 1)
        // if (payrollData?.staffPayroll[i]?.healthInsuranceDeductions < 1) {
        //   toast.error("At least one of your Health Insurance Deductions is 0, Please update.");
        //   return;
        // }

        if (payrollData?.staffPayroll[i]?.arrears) {
          if (payrollData?.uploads?.length < 1) {
            toast.error("Please attach documents for arrears entries");
            return;
          }

        }
      }
    }
    setPostComp(true);
    props.history.push(`/payroll/payroll-preview/?isA=false`);
  }

  const handleNetPay = () => {
    if (Array.isArray(payrollData.staffPayroll)) {
      // Create a copy of the state object
      const updatedState = { ...payrollData };

      // Create a copy of the staffPayroll array
      const updatedStaffPayroll = [...updatedState?.staffPayroll];

      // Update the specific element using the index
      const updatedStaffPayrollTemp = updatedStaffPayroll?.map((item) => (
        {
          ...item,
          netPay: (item?.baseSalary + item?.totalAllowances + item?.thirteenthMonth) - (item.tax + item.pensionContribution + item.otherDeductions),
          staffDeductions: removeDuplicates(item?.staffDeductions, "deductionId"),
          bonus: removeDuplicates(item?.bonus, "deductionId")
        }
      ))

      // Update the staffPayroll array in the copied state object
      updatedState.staffPayroll = updatedStaffPayrollTemp;

      // Set the state with the updated object
      setPayrollData(updatedState);
    }
  }

  const getEmployeePayroll = async () => {
    let mdaId = payrollData.mdaId;
    console.log(payrollData.mdaId);
    let res;
    if (mdaId === 0) {
      props.history.push("/payroll/payroll-initiation");
      return;
    } else {
      res = await get(`/Payroll/Staff/${mdaId}`);
    }
    if (res.status === "success") {
      if (postComp === false) {
        setPayrollData((prev) => ({
          ...prev,
          staffPayroll: res?.data?.map((data) => ({
            ...data,
            otherDeductions: "",
            cooperativeDeductions: "",
            thirteenthMonth: "",
          })),
        }));
      }

      if (res.data?.length < 1) {
        toast.error("MDA does not have any employees, please select a different MDA")
        props.history.push("/payroll/payroll-initiation")
      }
    }
    else {
      toast.error("MDA does not have any employees, please select a different MDA")

      props.history.push("/payroll/payroll-initiation")
    }


    console.log("res", res);
  };

  const getApprovedDeductions = async () => {
    let res = await get('/Deduction/Approved')
    console.log(res)
    if (res.status === "success") {
      setApprovedDeductions(res.data.map((item) => ({
        label: item.deductionItems,
        value: item.deductionId,
        all: item
      })))
    }

    setPayrollData((prev)=>{
      return {
       ...prev,
        staffPayroll: prev.staffPayroll.map((item) => ({
         ...item,
          staffDeductions: removeDuplicates(item.staffDeductions, "deductionId"),
          bonus: removeDuplicates(item.bonus, "deductionId")
        }))
      }
    })
  }

  useEffect(() => {
    if (isEdit === false) {
      getEmployeePayroll();
      handleNetPay()
      getApprovedDeductions();
    }

    getApprovedDeductions();

    // if(!Array.isArray(payrollData.staffPayroll) || payrollData.staffPayroll.length < 1){
    //   props.history.push("/payroll/payroll-initiation")
    // }

  }, []);


  // useEffect(() => {
  //   if (payrollData) {
  //     let newStaffPayroll = payrollData?.staffPayroll?.reduce(
  //       (accumulator, currentValue) => accumulator + currentValue,
  //       payrollData?.netPay,
  //     )
  //     setPayrollData({ ...payrollData, staffPayroll: newStaffPayroll })
  //   }

  // }, [payrollData?.staffPayroll])



  return (
    <div className='w-100'>
      <div>
        <div className="flex flex-v-center m-b-20">
          <img src={tune} alt="" />
          <p className="dash-text m-l-10"> Payroll Computation Sheet</p>
        </div>
        <div className="flex space-between m-b-20">
          <div className='flex flex-60 flex-v-center'>
            {/* <button onClick={() => setShowCoop(!showCoop)} className="rounded-btn m-r-10 pointer">Co-op Loans</button>
            <button onClick={() => setShowOthers(!showOthers)} className="rounded-btn m-r-10 pointer">Others</button>
            <button onClick={() => setShow13th(!show13th)} className="rounded-btn m-r-10 pointer">13th Month</button> */}
            {/* <button onClick={() => handleAdditional("enhs")} className="rounded-btn m-r-10 pointer">ENHS</button> */}
            {/* <button onClick={() => handleAdditional("edsg-his")} className="rounded-btn m-r-10 pointer">EDSG-HIS</button> */}
            {/* <button onClick={() => handleAdditional("cooperativeDeductions")} className="rounded-btn m-r-10 pointer">Co-op Loans</button> */}
            {/* <button onClick={() => handleAdditional("thirteenthMonth", [...additionalColumnsOptions, {value:"", label:"thirteenthMonth"}])} className="rounded-btn m-r-10 pointer">13th Month</button> */}
            {/* <button onClick={() => handleAdditional("union")} className="rounded-btn m-r-10 pointer">Union</button> */}
            {/* <button onClick={() => handleAdditional("arrears", [...additionalColumnsOptions, {label:"arrears", value:""}])} className="rounded-btn m-r-10 pointer">Arrears</button> */}
            {/* <button onClick={() => handleAdditional("LTG", [...additionalColumnsOptions, {label:"LTG", value:""} ])} className="rounded-btn m-r-10 pointer">LTG</button> */}
            {/* <button onClick={() => handleAdditional("healthInsuranceDeductions")} className="rounded-btn m-r-10 pointer">Health Insurance</button> */}
            {/* <button onClick={() => handleAdditional("otherDeductions")} className="rounded-btn m-r-10 pointer">Others</button> */}
            <div className="w-50">
              <p>Other Deductions:</p>
              <Select isMulti value={additionalColumnsOptions} options={approvedDeductions} onChange={(value) => handleAdditional(value?.label, value)} />
            </div>
          </div>
          <div className='flex space-between'>
            <button onClick={goToPreview} className="btn btd-red m-r-10 bg-red"> <div className='w-100 flex flex-v-center'><p>Preview Payroll</p> <img className='m-l-10' src={preview} alt="" /></div></button>
            {/* <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="btn w-100 "
              table="table-to-xls"
              filename={`Payroll for the month of ${months[payrollData?.month - 1]?.label}`}
              sheet="tablexls"
              buttonText={<div className='w-100 flex flex-v-center'><p>Download</p><img className='m-l-10' src={xls} alt="" /></div>} /> */}
            {/* <button className="btn center-text"> <div className='w-100 flex flex-v-center'><p>Download</p><img className='m-l-10' src={xls} alt="" /></div> </button> */}
          </div>
        </div>
        <p>Number of Employees:</p>
        <div className='flex space-between flex-v-end m-b-20'>
          <div className='w-100 m-r-20'>
            <TextInput disabled value={payrollData?.staffPayroll?.length} className="pay-input " placeholder="No of Employees" />
          </div>
          <div className='w-50'>
            <TextInput disabled value={payrollData?.mda} className="pay-input m-r-10" placeholder="MDA" />
            {/* <Select isDisabled value={{ value: payrollData?.mdaId, label: payrollData?.mda }} className="h-100" /> */}
          </div>
        </div>
        <div className="flex space-between">
          <div className="w-50 m-b-20 enc-border p-10">
            <h3 className="m-b-10">Bulk Upload:</h3>
            <div className="flex w-100">
              <div className="w-100 m-r-10">
                <p>Select Column: </p>
                <Select options={[...contextColumnOptions, ...statutoryOptions]} onChange={handleAdditionalColumnsOptions} />
              </div>
              <div className="w-100">
                <p>Enter Value: </p>
                <TextInput type='number' value={updateValue} onChange={handleUpdateChange} className="pay-inputer m-r-10" placeholder="Update Value" />
              </div>
            </div>
            <div className="flex-h-end flex">
              <button onClick={uploadValues} className="btn m-t-10">Upload</button>
            </div>

          </div>
          <div className="w-30 m-r-20 m-l-10 m-b-20 enc-border p-10">
            <h3 className="m-b-10">Bulk Upload Comment:</h3>
            <div className="flex w-100">
              <div className="w-100">
                <p>Enter Value: </p>
                <TextInput type='text' value={commentUpdateValue} onChange={handleCommentUpdateChange} className="pay-inputer m-r-10" placeholder="Update Value" />
              </div>
            </div>
            <div className="flex-h-end flex">
              <button onClick={uploadCommentValues} className="btn m-t-10">Upload</button>
            </div>

          </div>
          <div className="flex-40 w-100 ">
            <p className='m-t-10'>Upload Documents:</p>
            <UploadButton sendImagg={recImagg} className="w-100" />
            {
              payrollData?.uploads?.map((uploads) => (
                <div className='flex-align m-t-10'>
                  <AiOutlinePaperClip />
                  <a target="_blank" href={uploads} style={{ marginLeft: '3px', marginBottom: '4px' }}>
                    View Document
                  </a>
                  {/* <div
                                        className='pointer'
                                        style={{ display: 'inline', marginLeft: '4px', marginBottom: '0px' }}
                                        onClick={() => {
                                            del(idx);
                                        }}>
                                        <MdDelete style={{ fontSize: '16px' }} />
                                    </div> */}
                </div>
              ))
            }
          </div>

        </div>
        <div>

          <div className="m-b-10 flex flex-v-center">
            <span>Select All:</span><span><input onClick={handleSelectAll} checked={allOn} type="checkbox" name="all" id="all" /></span>
          </div>
          <ComputationTable bulkUploadArray={bulkUploadArray} setBulkUploadArray={setBulkUploadArray} additionalColumns={additionalColumns} tableId={tableId} isEdit={isEdit} showModal={showModal} setModalIndex={setModalIndex} setUserName={setUserName} tableData={payrollData?.staffPayroll || payrollData?.staffPayrollResponse} setShowModal={setShowModal} payrollData={payrollData} setPayrollData={setPayrollData} showOthers={showOthers} showCoop={showCoop} show13th={show13th} />
        </div>
      </div>
      {showModal && <ComputationModal isEdit={isEdit} userName={userName} closeModal={closeModal} modalIndex={modalIndex} setPayrollData={setPayrollData} payrollData={payrollData} review={false} />}
    </div>
  )
}

export default PayrollComputationSheet;
