import React, { useState } from "react";

import { AiOutlineDelete, AiOutlineSend } from 'react-icons/ai';
import { MdEdit } from 'react-icons/md';
import { MyContext } from "../../contexts";
import { getStatus } from "../../utility/general";

const Table = ({tableData}) => {
  // State variable to store the table data
  


  return (
    <div className="w-100 ">
      <table className="bordered-table">
        <thead className="border-top-none">
          <tr className="border-top-none">
            <th>Agency</th>
            <th>Initiator</th>
            <th>Payroll Title</th>
            <th>Approval Status</th>
            <th>Last Treated</th>
          
          </tr>
        </thead>
        <tbody className="white-bg">
          {Array.isArray(tableData) && tableData.map((item, index) => (
            <tr key={item?.id}>
              <td>{item?.mda}</td>
              <td>{item?.initiator}</td>
              <td>{item?.title}</td>
              {/* <td>
                                    {getStatus(item)}
                                </td> */}

              <td >
                <div className="flex flex-v-center h-100">
                  <div
                    className={`rounded-pill m-r-10 ${item.statusDetails === "ApprovalCompleted"
                      ? "green-bg"
                      : item.statusDetails === "Rejected"
                        ? "red-bg"
                        : item.statusDetails === "Pending"
                          ? "yellow-bg"
                          : "gray-bg"
                      }`}
                  ></div>{" "}
                  {getStatus(item)}
                </div>

              </td>


              <td>
                {new Date(item?.modifiedDate).toUTCString()}
              </td>
            

            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
