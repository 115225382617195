import React from "react";
import { IoMdClose } from "react-icons/io";
const ConfirmationModal = ({ cancel, confirm, message }) => {
  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={cancel} />
      <div className="modal-box max-w-400">
        <div className="p-20">
          <h3>Confirmation</h3>
          <p className="m-t-20">{message}</p>
          <div className="flex flex-v-center m-t-20 ">
            {" "}
            <button
              onClick={cancel}
              className="add-button pointer p-20 w-100 m-t-10 pointer m-r-10"
            >
              NO
            </button>
            <button onClick={confirm} className="btn p-20 w-100 m-t-10">
              YES
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
