import React, { useEffect, useState } from 'react';
import Table from '../layouts/Table';
import LocationDetails from '../layouts/Greeting';
import Card from '../layouts/Card';
import tune from "../../assets/svg/tune.svg"
import FilterHeader from '../layouts/FilterHeader';
import { get } from '../../utility/fetch';


const Dashboard = ({ history }) => {
  const [title, setTitle] = useState('');
  const [dashboardStats, setDashboardStats] = useState({});
  const [tableData, setTableData] = useState([])


  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'EDSG - Dashboard';
    setTitle('Dashboard');
    getDashboardDetails();
    getPayroll();
  }, []);

  const getPayroll = async () => {
    const res = await get("/Payroll/Paginated?PageNumber=1&PageSize=10")
    console.log(res)
    setTableData(res?.data?.items)
}

  const getDashboardDetails = async() => {
      const res = await get(`/Payroll/Dashboard`)
      setDashboardStats(res?.data)
  }

  return (
    <div className='w-100'>
      <LocationDetails />

      <div className='flex w-100 space-between m-t-20'>
        {/* <Card title={"Uninitiated payroll"} count = {dashboardStats?.unintiatedPayroll} /> */}
        <Card title={"Initiated Payroll"} count = {dashboardStats?.intiatedPayroll} />
        <Card title={"Payroll Review in Progress"} count = {dashboardStats?.payrollReviewInProgress} />
        <Card title={"Completed Payroll"} count = {dashboardStats?.completedPayroll} />
      </div>

      <div className='flex flex-v-center m-t-20'>
        <img className='m-r-10' src={tune} alt="" /> <span className='dash-text'>MDA/Agency Payroll Status Log</span>
      </div>

      <div>
        <FilterHeader />
      </div>

      <div className='w-100 m-t-20'>
        <Table tableData={tableData} className="" />
      </div>

    </div>
  );
};

export default Dashboard;
