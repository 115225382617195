import { useState, useEffect } from "react";
import tune from "../../../assets/svg/tune.svg";
import DeductionTable from "../../layouts/DeductionTable";
import AddDeductionItem from "../../layouts/modals/AddDeductionItem";
import { get } from "../../../utility/fetch";
const DeductionManager = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deductions, setDeductions] = useState([]);
  const [deductionSource, setDeductionSource] = useState([]);

  const getDeductionSources = async () => {
    try {
      const res = await get("/DeductionSource");
      setDeductionSource(
        res.data?.map((item) => ({
          label: item.description,
          value: item.id,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };
  const getDeductions = async () => {
    try {
      const res = await get("/Deduction/Approved");
      setDeductions(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getDeductionSources();
    getDeductions();
  }, []);
  return (
    <div className="w-100">
      <div className="flex space-between">
        <div className="flex flex-v-center m-t-20">
          <img className="m-r-10" src={tune} alt="" />
          <span className="dash-text">Salary Deduction Manager</span>
        </div>
        <a href="/payroll/settings/deduction-manager/initiate-add-request/0">
          <button className="btn p-15">+ Add a Deduction Item</button>
        </a>
      </div>
      <div className="m-t-20">
        <DeductionTable
          tableData={deductions}
          fetchData={getDeductions}
          deductionSource={deductionSource}
        />
      </div>
      {isModalOpen && (
        <AddDeductionItem
          closeModal={closeModal}
          fetchData={getDeductions}
          deductionSource={deductionSource}
        />
      )}
    </div>
  );
};

export default DeductionManager;
