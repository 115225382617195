const FilterHeader = () => {
    return (
        <div className="w-100 m-t-20">
            <div className="filter-head w-100 flex space-between flex-v-center">
                <div className="w-75">
                    <label className="dash-label" htmlFor="search">Search</label>
                    <input className="filter-input w-90" type="text" name="search" id="search" />
                </div>
                <div className="w-75">
                    <label className="dash-label" htmlFor="search">Start Date</label>
                    <input className="filter-input w-90" type="date" name="search" id="search" />
                </div>
                <div className="w-75">
                    <label className="dash-label" htmlFor="search">End Date</label>
                    <input className="filter-input w-90" type="date" name="search" id="search" />
                </div>
                <div className="w-75 flex flex-h-center">
                    <button className="btn slim-b w-80">
                        Search
                    </button>
                </div>

            </div>

        </div>
    )
}
export default FilterHeader;