/* eslint-disable max-len */
import React, { useEffect, useContext } from 'react';
// import Cookies from 'js-cookie';
// import { UserInfoContext } from '../../contexts';
import { post } from '../../utility/fetch';
import coatOfArm from '../../assets/images/coat-of-arm.png';
import Footer from '../layouts/Footer';
import notification from '../../utility/notification';
import logo from '../../assets/images/Group-2.png';
import greenzone from '../../assets/images/download.png';

const Home = (props) => {
  // const { setCurrentUser } = useContext(UserInfoContext);

  const makePostRequest = async () => {
    const baseURL = new URLSearchParams(props.location.search).get('base');
    try {
      const emp = new URLSearchParams(props.location.search).get('emp');
      const base = new URLSearchParams(props.location.search).get('base');
      const { status, data } = await post(
        `/Authentication/AccessToken?Token=${emp}`
      );
      if (status === 'success') {
        sessionStorage.setItem('token', data.token);
        localStorage.setItem("baseURL", base, { expires: 0.0416665 })
        localStorage.setItem("homeLink", data.homeLink, { expires: 0.0416665 })
        localStorage.setItem('USER_INFO', JSON.stringify(data));
        localStorage.setItem('homeLink', data.homeLink, { expires: 0.0416665 });
        // localStorage.setItem('token', data.token, { expires: 0.0416665 });
        localStorage.setItem('userId', data.userID, { expires: 0.0416665 });
        localStorage.setItem('name', data.name, { expires: 0.0416665 });
        localStorage.setItem('role', data.role, { expires: 0.0416665 });
        localStorage.setItem('accessLevelId', data.accessLevelId, { expires: 0.0416665 });
        localStorage.setItem('access', data.accesslevel, { expires: 0.0416665 });
        localStorage.setItem('tokenExist', true, { expires: 0.0416665 });

        // setCurrentUser(data);
        props.history.push('/payroll');
      }
    } catch (error) {
      localStorage.removeItem('USER_INFO');
      notification({
        title: 'ACCESS DENIED',
        message: 'Sorry, This user does not have access to this application. Please Contact Admin',
        type: 'warning',
      });
    }
    // setTimeout(() => window.location.assign("/"), 6000);
  };

  useEffect(() => {
    makePostRequest();
  }, []);

  return (
    <div className="w-100">
      <div className="banner">
        <div className="login">
          <div className="coat-of-arm">
            <div><img src={logo} alt="" /></div>
            <img className="m-b-10" src={greenzone} alt="" />
            <h1 style={{ color: "green", fontWeight: "bolder" }}>GREENGOV</h1>
            {/* <h4>GOVERNMENT</h4> */}
          </div>
          <div className="form">Loading...</div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
