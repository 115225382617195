import React, { useContext, useState } from "react";
import notes from "../../assets/svg/notes.svg";
import { filterUniqueByType, numberWithCommas, removeDuplicates, stripCommas, sumValuesByType } from "../../utility/general";
import { useEffect } from "react";
import { MyContext } from "../../contexts";
import UploadModal from "./UploadModal";
import UploadButton from "./UploadButton";
import { AiOutlinePaperClip } from "react-icons/ai";
import SearchInput from "../inputs/SearchInput";
import { MdCancel } from "react-icons/md";

const tableHeaders = [
  "Select",
  "Staff ID",
  "Staff Name",
  "Salary Structure",
  "Grade/Step",
  "Base Salary",
  "Total Allowances",
  "Tax",
  "Pension Contribution",
  "Arrears",
  "Net Pay",
  "Notes",
]

const ComputationTable = ({
  payrollData,
  bulkUploadArray,
  setBulkUploadArray,
  setPayrollData,
  isEdit,
  setUserName,
  setShowModal,
  tableData,
  setModalIndex,
  review,
  showOthers,
  show13th,
  showCoop,
  tableId,
}) => {
  const {
    // tableHeaders,
    setAdditionalColumns,
    additionalColumns,
    setTableHeaders,
    contextColumnOptions,
    setContextColumnOptions
  } = useContext(MyContext);

  const [searchValue, setSearchValue] = useState("");
  const [computationColumns, setComputationColumns] = useState([]);
  const [columnNames, setColumnNames] = useState([]);

  const doAdditionalColumns = () => {
    let tempArray = [];

    tableData?.forEach(element => tempArray.push(element?.staffDeductions))

    console.log(tableData)
    console.log(tempArray.flat())
    console.log(new Set(tempArray.flat()))
    // Create a Map to hold unique items
    const uniqueMap = new Map();
    tempArray.flat().forEach(item => {
      // Use a unique key for each item
      const key = `${item.deductionItem}`;
      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, item);
      }
    });

    // Convert the Map values to an array
    const uniqueArray = Array.from(uniqueMap.values());
    console.log(uniqueArray)
    return [uniqueArray];
  }

  const getColumnNames = () =>{
    setColumnNames([...new Set(tableData?.map((item) => item?.staffDeductions?.map((staffDeduction) => staffDeduction.deductionItem)).concat(tableData?.map((item) => item?.bonus?.map((staffDeduction) => staffDeduction.deductionItem))).flat())])
  }

  const updateStaffDeductions = () => {
    // Step 1: Collect all unique deduction items
    const uniqueDeductions = new Map();

    tableData.forEach(record => {
      if (record.staffDeductions && record.staffDeductions.length > 0) {
        record.staffDeductions.forEach(deduction => {
          uniqueDeductions.set(deduction.deductionId, { ...deduction });
        });
      }
    });

    const uniqueDeductionsArray = Array.from(uniqueDeductions.values());

    // Step 2: Update each record to ensure it has all unique deduction items
    const updatedData = tableData.map(record => {
      let updatedRecord = { ...record };

      if (!updatedRecord.staffDeductions) {
        updatedRecord.staffDeductions = [];
      }

      const existingDeductionIds = new Set(updatedRecord.staffDeductions.map(d => d.deductionId));

      uniqueDeductionsArray.forEach(deductionTemplate => {
        if (!existingDeductionIds.has(deductionTemplate.deductionId)) {
          updatedRecord.staffDeductions.push({
            ...deductionTemplate,
            amount: 0, // Set amount to 0 for missing items
            id: undefined // Ensure no id property for new items
          });
        }
      });

      console.log(updatedRecord)
      return updatedRecord;
    });

    // Update the state with the new data
    // setUpdatedData(updatedData);
    console.log(updatedData)
  };


  const getUniqueDeductionNames = (data) => {
    // Step 1: Flatten all deduction arrays into a single array
    const allDeductions = data.flatMap(item => item.staffDeductions);
  
    // Step 2: Use a Map to store unique deduction objects based on deductionItem
    const uniqueDeductionsMap = new Map();
    allDeductions.forEach(item => {
      if (item?.deductionItem) {
        uniqueDeductionsMap.set(item.deductionItem, item);
      }
    });
  
    // Step 3: Convert the Map values back to an array
    const uniqueDeductions = Array.from(uniqueDeductionsMap.values());
  
    console.log(uniqueDeductions);
  
    return uniqueDeductions;
  };

  const getUniqueBonusNames = (data) => {
    // Step 1: Flatten all deduction arrays into a single array
    const allDeductions = data.flatMap(item => item.bonus);
  
    // Step 2: Use a Map to store unique deduction objects based on deductionItem
    const uniqueDeductionsMap = new Map();
    allDeductions.forEach(item => {
      if (item?.deductionItem) {
        uniqueDeductionsMap.set(item.deductionItem, item);
      }
    });
  
    // Step 3: Convert the Map values back to an array
    const uniqueDeductions = Array.from(uniqueDeductionsMap.values());
  
    console.log(uniqueDeductions);
  
    return uniqueDeductions;
  };

  useEffect(() => {
    handleNetPay();
    setComputationColumns(...doAdditionalColumns())
    getColumnNames();
    updateStaffDeductions()
    if (review === true) {
      setAdditionalColumns([
        "thirteenthMonth",
        "cooperativeDeductions",
        "otherDeductions",
        "healthInsuranceDeductions",
        "arrears",
      ]);

      let temp = tableHeaders;
      temp.splice(temp.length - 2, 0, "thirteenthMonth");
      temp.splice(temp.length - 2, 0, "cooperativeDeductions");
      temp.splice(temp.length - 2, 0, "otherDeductions");
      temp.splice(temp.length - 2, 0, "healthInsuranceDeductions");
      temp.splice(temp.length - 2, 0, "arrears");
      setTableHeaders(temp);
    }
  }, []);

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [recordStat, setRecordStat] = useState({});

  const handleUploadModal = (e) => {
    setRecordStat(e);
    setShowUploadModal(true);
  };

  const handleBulk = (item) => {
    if (bulkUploadArray.includes(item.staffId)) {
      setBulkUploadArray(bulkUploadArray.filter((x) => x !== item.staffId));
    } else {
      setBulkUploadArray([...bulkUploadArray, item.staffId]);
    }
  };

  const handleOthers = (staffId, value) => {
    // Create a copy of the state object
    const updatedState = { ...payrollData };

    // Create a copy of the staffPayroll array
    let updatedStaffPayroll = [...updatedState?.staffPayroll];

    updatedStaffPayroll = updatedStaffPayroll.map((payroll) => {
      if (payroll.staffId === staffId) {
        return {
          ...payroll,
          otherDeductions: Number(value),
          netPay:
            parseFloat(payroll?.baseSalary +
              payroll?.totalAllowances +
              payroll?.otherBonus +
              payroll?.ltg +
              payroll?.arrears
              -
              (payroll?.tax +
                payroll?.pensionContribution +
                payroll?.healthInsuranceDeductions +
                parseFloat(value) +
                payroll?.cooperativeDeductions)),
        }
      }
      else {
        return payroll
      }
    })

    // // Update the specific element using the index
    // payroll = {
    //   ...updatedStaffPayroll[index],
    //   otherDeductions: Number(value),
    //   netPay:
    //     parseFloat(updatedStaffPayroll[index]?.baseSalary +
    //       updatedStaffPayroll[index]?.totalAllowances +
    //       updatedStaffPayroll[index]?.otherBonus +
    //       updatedStaffPayroll[index]?.ltg +
    //       updatedStaffPayroll[index]?.arrears
    //       -
    //       (updatedStaffPayroll[index]?.tax +
    //         updatedStaffPayroll[index]?.pensionContribution +
    //         updatedStaffPayroll[index]?.healthInsuranceDeductions +
    //         parseFloat(value) +
    //         updatedStaffPayroll[index]?.cooperativeDeductions)),
    // };

    // Update the staffPayroll array in the copied state object
    updatedState.staffPayroll = updatedStaffPayroll;

    // Set the state with the updated object
    setPayrollData(updatedState);
  };

  console.log(payrollData);


  const removeColumn = (columnObject, type) => {
    console.log(type, columnObject);
    if (type === "d") {
      console.log(contextColumnOptions.filter((object) => object.deductionId !== columnObject.deductionId))
      setContextColumnOptions(contextColumnOptions.filter((object) => object.deductionId !== columnObject.deductionId))

      let payR = payrollData?.staffPayroll?.map((payroll) => {
        payroll.staffDeductions = payroll?.staffDeductions?.filter((item) => item.deductionId !== columnObject.deductionId)
        return payroll
      });
      setPayrollData((prev) => ({ ...prev, staffPayroll: payR, staffPayrollResponse: payR }))
    }
    // setAdditionalColumns(additionalColumns.filter((x) => x!== "notes"));
  }

  const handleNetPay = () => {
    if (Array.isArray(payrollData.staffPayroll)) {
      // Create a copy of the state object
      const updatedState = { ...payrollData };

      // Create a copy of the staffPayroll array
      let updatedStaffPayroll = [...updatedState?.staffPayroll];

      // Update the specific element using the index
      let updatedStaffPayrollTemp = updatedStaffPayroll?.map((item) => ({
        ...item,
        netPay:
          parseFloat((
            parseFloat(Number(item?.baseSalary || 0) +
              Number(item?.totalAllowances || 0) +
              Number(item?.arrears || 0) +
              Number(item?.otherBonus || 0) +
              Number(item?.ltg || 0) +
              Number(item?.thirteenthMonth || 0) || 0) -
            (Number(item?.tax || 0) +
              Number(item?.pensionContribution || 0) +
              Number(item?.otherDeductions || 0) +
              Number(item?.healthInsuranceDeductions || 0) +
              Number(item?.cooperativeDeductions || 0))
          ) + parseFloat(item?.bonus?.reduce((acc, item) => acc + item?.amount, 0)) -
            parseFloat(item?.staffDeductions?.reduce((acc, item) => acc + item?.amount, 0))).toFixed(2),
      }));
      // Update the staffPayroll array in the copied state object
      updatedState.staffPayroll = updatedStaffPayrollTemp;

      // Set the state with the updated object
      setPayrollData(updatedState);
    }
  };

  console.log(computationColumns)

  const handleNet = (index, column, value) => {

    console.log(column)
    console.log(value)
    console.log(index)

    // Create a copy of the state object
    const updatedState = { ...payrollData };

    // Create a copy of the staffPayroll array
    let updatedStaffPayroll = [...updatedState?.staffPayroll];

    let temp = updatedStaffPayroll.map((payroll) => {
      console.log(payroll);
      if (payroll.staffId === index) {

        let tempDeduction = payroll.staffDeductions.map((deductions) => {
          console.log(deductions);
          if (deductions.deductionId === column.deductionId) {
            return {
              ...deductions,
              amount: Number(value)
            }
          }
          else {
            return deductions
          }
        })

        let newPayroll = {
          ...payroll,
          staffDeductions: tempDeduction
        }

        return newPayroll
      }
      else {
        return payroll
      }
    })

    // // Update the specific element using the index
    // updatedStaffPayroll[index] = {
    //   ...updatedStaffPayroll[index],
    //   [updatedStaffPayroll[index][column]]: Number(value),
    //   // netPay: updatedStaffPayroll[index].netPay - Number(value),
    // };
    console.log(temp);
    // Update the staffPayroll array in the copied state object
    updatedState.staffPayroll = temp;

    // Set the state with the updated object
    setPayrollData(updatedState);
  };

  // {
  //     "employeeId": 0,
  //     "baseSalary": 0,
  //     "totalAllowances": 0,
  //     "tax": 0,
  //     "pensionContribution": 0,
  //     "otherDeductions": 0,
  //     "netPay": 0,
  //     "notes": "string"
  //   }

  const handleArrears = (staffId, value) => {

    console.log(staffId)
    console.log(value)
    // Create a copy of the state object
    const updatedState = { ...payrollData };

    // Create a copy of the staffPayroll array
    let updatedStaffPayroll = [...updatedState?.staffPayroll];

    updatedStaffPayroll = updatedStaffPayroll.map((payroll) => {
      if (payroll.staffId === staffId) {
        return {
          ...payroll,
          arrears: Number(value),
          netPay:
            parseFloat(payroll.baseSalary +
              payroll.totalAllowances +
              payroll.otherBonus +
              payroll.ltg +
              Number(value) -
              (payroll.tax +
                payroll.pensionContribution +
                payroll.healthInsuranceDeductions +
                payroll.otherDeductions +
                payroll.cooperativeDeductions)),
        }
      }
      else {
        return payroll
      }
    })


    // let temp = updatedStaffPayroll.find((staffPayroll) => staffPayroll.staffId === staffId)

    // temp = {
    //   ...temp,
    //   arrears: Number(value),
    //     netPay:
    //       parseFloat(updatedStaffPayroll[index]?.baseSalary +
    //         updatedStaffPayroll[index]?.totalAllowances +
    //         updatedStaffPayroll[index]?.otherBonus +
    //         updatedStaffPayroll[index]?.ltg +
    //         Number(value) -
    //         (updatedStaffPayroll[index]?.tax +
    //           updatedStaffPayroll[index]?.pensionContribution +
    //           updatedStaffPayroll[index]?.healthInsuranceDeductions +
    //           updatedStaffPayroll[index]?.otherDeductions +
    //           updatedStaffPayroll[index]?.cooperativeDeductions)),
    // }

    // Update the specific element using the index
    // updatedStaffPayroll[index] = {
    //   ...updatedStaffPayroll[index],
    //   arrears: Number(value),
    //   netPay:
    //     parseFloat(updatedStaffPayroll[index]?.baseSalary +
    //       updatedStaffPayroll[index]?.totalAllowances +
    //       updatedStaffPayroll[index]?.otherBonus +
    //       updatedStaffPayroll[index]?.ltg +
    //       Number(value) -
    //       (updatedStaffPayroll[index]?.tax +
    //         updatedStaffPayroll[index]?.pensionContribution +
    //         updatedStaffPayroll[index]?.healthInsuranceDeductions +
    //         updatedStaffPayroll[index]?.otherDeductions +
    //         updatedStaffPayroll[index]?.cooperativeDeductions)),
    // };


    // Update the staffPayroll array in the copied state object
    updatedState.staffPayroll = updatedStaffPayroll;

    // Set the state with the updated object
    setPayrollData(updatedState);
  };


  const handleOtherBonus = (staffId, value) => {
    // Create a copy of the state object
    const updatedState = { ...payrollData };

    // Create a copy of the staffPayroll array
    let updatedStaffPayroll = [...updatedState?.staffPayroll];

    updatedStaffPayroll = updatedStaffPayroll.map((payroll) => {
      if (payroll.staffId === staffId) {
        return {
          ...payroll,
          otherBonus: Number(value),
          netPay:
            parseFloat(payroll?.baseSalary +
              payroll?.totalAllowances +
              payroll?.ltg +
              payroll?.thirteenthMonth +
              Number(value) -
              (payroll?.tax +
                payroll?.pensionContribution +
                payroll?.healthInsuranceDeductions +
                payroll?.otherDeductions +
                payroll?.cooperativeDeductions)),
        }
      }
      else {
        return payroll
      }
    })

    // // Update the specific element using the index
    // updatedStaffPayroll[index] = {
    //   ...updatedStaffPayroll[index],
    //   otherBonus: Number(value),
    //   netPay:
    //     parseFloat(updatedStaffPayroll[index]?.baseSalary +
    //       updatedStaffPayroll[index]?.totalAllowances +
    //       updatedStaffPayroll[index]?.ltg +
    //       updatedStaffPayroll[index]?.thirteenthMonth +
    //       Number(value) -
    //       (updatedStaffPayroll[index]?.tax +
    //         updatedStaffPayroll[index]?.pensionContribution +
    //         updatedStaffPayroll[index]?.healthInsuranceDeductions +
    //         updatedStaffPayroll[index]?.otherDeductions +
    //         updatedStaffPayroll[index]?.cooperativeDeductions)),
    // };


    // Update the staffPayroll array in the copied state object
    updatedState.staffPayroll = updatedStaffPayroll;

    // Set the state with the updated object
    setPayrollData(updatedState);
  };


  const handleLtg = (staffId, value) => {
    // Create a copy of the state object
    const updatedState = { ...payrollData };

    // Create a copy of the staffPayroll array
    let updatedStaffPayroll = [...updatedState?.staffPayroll];

    updatedStaffPayroll = updatedStaffPayroll.map((payroll) => {
      if (payroll?.staffId === staffId) {
        return {
          ...payroll,
          ltg: Number(value),
          netPay:
            parseFloat(payroll.baseSalary +
              payroll.totalAllowances +
              payroll.otherBonus +
              payroll.thirteenthMonth +
              Number(value) -
              (payroll.tax +
                payroll.pensionContribution +
                payroll.healthInsuranceDeductions +
                payroll.otherDeductions +
                payroll.cooperativeDeductions)),
        }
      }
      else {
        return payroll
      }
    })

    // // Update the specific element using the index
    // updatedStaffPayroll[index] = {
    //   ...updatedStaffPayroll[index],
    //   ltg: Number(value),
    //   netPay:
    //     parseFloat(updatedStaffPayroll[index]?.baseSalary +
    //       updatedStaffPayroll[index]?.totalAllowances +
    //       updatedStaffPayroll[index]?.otherBonus +
    //       updatedStaffPayroll[index]?.thirteenthMonth +
    //       Number(value) -
    //       (updatedStaffPayroll[index]?.tax +
    //         updatedStaffPayroll[index]?.pensionContribution +
    //         updatedStaffPayroll[index]?.healthInsuranceDeductions +
    //         updatedStaffPayroll[index]?.otherDeductions +
    //         updatedStaffPayroll[index]?.cooperativeDeductions)),
    // };


    // Update the staffPayroll array in the copied state object
    updatedState.staffPayroll = updatedStaffPayroll;

    // Set the state with the updated object
    setPayrollData(updatedState);
  };

  const handleTax = (staffId, value) => {
    // Create a copy of the state object
    const updatedState = { ...payrollData };

    // Create a copy of the staffPayroll array
    let updatedStaffPayroll = [...updatedState?.staffPayroll];

    updatedStaffPayroll = updatedStaffPayroll.map((payroll) => {
      if (payroll?.staffId === staffId) {
        return {
          ...payroll,
          tax: Number(value),
          netPay:
            parseFloat(payroll?.baseSalary +
              payroll?.ltg +
              payroll?.otherBonus +
              payroll?.totalAllowances +
              payroll?.arrears
              -
              (Number(value) +
                payroll?.pensionContribution +
                payroll?.healthInsuranceDeductions +
                payroll?.otherDeductions +
                payroll?.cooperativeDeductions)),
        }
      }
      else {
        return payroll
      }
    })

    // // Update the specific element using the index
    // updatedStaffPayroll[index] = {
    //   ...updatedStaffPayroll[index],
    //   tax: Number(value),
    //   netPay:
    //     parseFloat(updatedStaffPayroll[index]?.baseSalary +
    //       updatedStaffPayroll[index]?.ltg +
    //       updatedStaffPayroll[index]?.otherBonus +
    //       updatedStaffPayroll[index]?.totalAllowances +
    //       updatedStaffPayroll[index]?.arrears
    //       -
    //       (Number(value) +
    //         updatedStaffPayroll[index]?.pensionContribution +
    //         updatedStaffPayroll[index]?.healthInsuranceDeductions +
    //         updatedStaffPayroll[index]?.otherDeductions +
    //         updatedStaffPayroll[index]?.cooperativeDeductions)),
    // };

    // Update the staffPayroll array in the copied state object
    updatedState.staffPayroll = updatedStaffPayroll;

    // Set the state with the updated object
    setPayrollData(updatedState);
  };

  const handlePensionContribution = (staffId, value) => {
    // Create a copy of the state object
    const updatedState = { ...payrollData };

    // Create a copy of the staffPayroll array
    let updatedStaffPayroll = [...updatedState?.staffPayroll];

    updatedStaffPayroll = updatedStaffPayroll.map((payroll) => {
      if (payroll?.staffId === staffId) {
        return {
          ...payroll,
          pensionContribution: Number(value),
          netPay:
            parseFloat(payroll.baseSalary +
              payroll.totalAllowances +
              payroll.arrears +
              payroll.ltg +
              payroll.otherBonus
              -
              (Number(value) +
                payroll.tax +
                payroll.healthInsuranceDeductions +
                payroll.otherDeductions +
                payroll.cooperativeDeductions)),
        }
      }
      else {
        return payroll
      }
    })

    // // Update the specific element using the index
    // updatedStaffPayroll[index] = {
    //   ...updatedStaffPayroll[index],
    //   pensionContribution: Number(value),
    //   netPay:
    //     parseFloat(updatedStaffPayroll[index]?.baseSalary +
    //       updatedStaffPayroll[index]?.totalAllowances +
    //       updatedStaffPayroll[index]?.arrears +
    //       updatedStaffPayroll[index]?.ltg +
    //       updatedStaffPayroll[index]?.otherBonus
    //       -
    //       (Number(value) +
    //         updatedStaffPayroll[index]?.tax +
    //         updatedStaffPayroll[index]?.healthInsuranceDeductions +
    //         updatedStaffPayroll[index]?.otherDeductions +
    //         updatedStaffPayroll[index]?.cooperativeDeductions)),
    // };

    // Update the staffPayroll array in the copied state object
    updatedState.staffPayroll = updatedStaffPayroll;

    // Set the state with the updated object
    setPayrollData(updatedState);
  };


  console.log(
    tableData?.map((item, idx) => {
    
        return  sumValuesByType(tableData, item?.staffDeductions[idx]?.deductionItem)
   
    })
  )

  console.log(sumValuesByType(tableData, "WHT"))

  const handleHealthInsuranceDeductions = (staffId, value) => {
    // Create a copy of the state object
    const updatedState = { ...payrollData };

    // Create a copy of the staffPayroll array
    let updatedStaffPayroll = [...updatedState?.staffPayroll];

    updatedStaffPayroll = updatedStaffPayroll.map((payroll) => {
      if (payroll?.staffId === staffId) {
        return {
          ...payroll,
          healthInsuranceDeductions: Number(value),
          netPay:
            parseFloat(payroll?.baseSalary +
              payroll?.totalAllowances +
              payroll?.ltg +
              payroll?.otherBonus +
              payroll?.arrears
              -
              (Number(value) +
                payroll?.tax +
                payroll?.pensionContribution +
                payroll?.otherDeductions +
                payroll?.cooperativeDeductions)),
        }
      }
      else {
        return payroll
      }
    })

    // // Update the specific element using the index
    // updatedStaffPayroll[index] = {
    //   ...updatedStaffPayroll[index],
    //   healthInsuranceDeductions: Number(value),
    //   netPay:
    //     parseFloat(updatedStaffPayroll[index]?.baseSalary +
    //       updatedStaffPayroll[index]?.totalAllowances +
    //       updatedStaffPayroll[index]?.ltg +
    //       updatedStaffPayroll[index]?.otherBonus +
    //       updatedStaffPayroll[index]?.arrears
    //       -
    //       (Number(value) +
    //         updatedStaffPayroll[index]?.tax +
    //         updatedStaffPayroll[index]?.pensionContribution +
    //         updatedStaffPayroll[index]?.otherDeductions +
    //         updatedStaffPayroll[index]?.cooperativeDeductions)),
    // };

    // Update the staffPayroll array in the copied state object
    updatedState.staffPayroll = updatedStaffPayroll;

    // Set the state with the updated object
    setPayrollData(updatedState);
  };

  console.log([...new Set(tableData?.map((item) => item?.staffDeductions?.map((staffDeduction) => staffDeduction.deductionItem)).concat(tableData?.map((item) => item?.bonus?.map((staffDeduction) => staffDeduction.deductionItem))).flat())])

  const handleSearch = (e) => {
    console.log(e.target.value)
    setSearchValue(e.target.value);
  }

  const handleCoop = (index, value) => {
    // Create a copy of the state object
    const updatedState = { ...payrollData };

    // Create a copy of the staffPayroll array
    let updatedStaffPayroll = [...updatedState?.staffPayroll];

    // Update the specific element using the index
    updatedStaffPayroll[index] = {
      ...updatedStaffPayroll[index],
      cooperativeDeductions: Number(value),
      netPay:
        parseFloat(updatedStaffPayroll[index]?.baseSalary +
          updatedStaffPayroll[index]?.totalAllowances +
          updatedStaffPayroll[index]?.ltg +
          updatedStaffPayroll[index]?.otherBonus +
          updatedStaffPayroll[index]?.thirteenthMonth -
          (updatedStaffPayroll[index]?.tax +
            updatedStaffPayroll[index]?.pensionContribution +
            updatedStaffPayroll[index]?.otherDeductions +
            Number(value))),
    };

    // Update the staffPayroll array in the copied state object
    updatedState.staffPayroll = updatedStaffPayroll;

    // Set the state with the updated object
    setPayrollData(updatedState);
  };

  const handleShowModal = (index, name) => {
    setModalIndex(index);
    setUserName(name);
    setShowModal(true);
  };


  console.log(columnNames?.map((item, idx) => {
       return sumValuesByType(tableData, item)
  }))

  console.log(payrollData)
  console.log(tableData[0]?.staffDeductions.sort((a, b) =>{console.log(`Comparing ${a.deductionItem} and ${b.deductionItem}`); return a.deductionItem.localeCompare(b.deductionItem)}))
  console.log(tableData[0]?.staffDeductions.sort((a, b) => {console.log(`Comparing ${a.deductionItem} and ${b.deductionItem}`); return b.deductionItem.localeCompare(a.deductionItem)}))

  return (
    <div>
      <div className="flex flex-h-end m-b-20">
        <SearchInput onChange={handleSearch} />
      </div>
      <div className="w-100 scrollable">
        <table id={tableId} className="bordered-table">
          <thead className="border-top-none">
            <tr className="border-top-none">

              <th>
                Select
              </th>
              <th>
                Staff ID
              </th>
              <th>
                Staff Name
              </th>
              <th>
                Salary Structure
              </th>
              <th>
                Grade/Step
              </th>
              <th>
                Base Salary
              </th>
              <th>
                Total Allowances
              </th>
              <th>
                Tax
              </th>
              <th>
                Pension Contribution
              </th>
              <th>
                Health Insurance
              </th>
              <th>
                Arrears
              </th>
              <th>
                Other Bonuses
              </th>
              <th>
                LTG
              </th>
              <th>
                Other Deductions
              </th>
              {
                getUniqueBonusNames(tableData)?.sort((a,b) => (a.deductionItem.localeCompare(b.deductionItem))).map((header) => (
                  <th className="w-30_ w-100 ">
                    <span className="">{header?.deductionItem}</span>
                    <span onClick={() => removeColumn(header, "d")} className="float-right pointer"><MdCancel color="red" /></span>
                  </th>
                ))
              }
              {
                getUniqueDeductionNames(tableData)?.sort((a,b) => (a.deductionItem.localeCompare(b.deductionItem))).map((header) => (
                  <th className="w-30_ w-100 ">
                    <span className="">{header?.deductionItem}</span>
                    <span onClick={() => removeColumn(header, "d")} className="float-right pointer"><MdCancel color="red" /></span>
                  </th>
                ))
              }
              <th>
                Net Pay
              </th>
              <th>
                Notes
              </th>
              {/* {tableHeaders?.map((name) => (
                <th>{name}</th>
              ))} */}
            </tr>
          </thead>
          <tbody className="white-bg view-det-pane">
            {tableData
              ?.filter((item) => item?.staffName.toLowerCase()?.includes(searchValue?.toLowerCase()) || item?.staffId?.toLowerCase().includes(searchValue?.toLowerCase()))
              ?.sort((a, b) => a.staffName - b.staffName)
              ?.map((item, index) => (
                <tr key={index}>

                  <td>
                    <input
                      onClick={() => handleBulk(item)}
                      checked={bulkUploadArray?.includes(item?.staffId)}
                      type="checkbox"
                      value=""
                    />
                  </td>
                  <td>{item?.staffId}</td>
                  <td>{item?.staffName}</td>
                  <td>{item?.salarytructure}</td>
                  <td>{item?.grade}</td>
                  <td>{`₦${numberWithCommas(item?.baseSalary)}`}</td>
                  <td>{`₦${numberWithCommas(item?.totalAllowances)}`}</td>
                  <td style={{ padding: "0px" }}>{(review) ? item?.tax : <input value={item?.tax} onChange={(e) => handleTax(item?.staffId, e?.target?.value)} type="number" className="tableInput" />}</td>
                  <td style={{ padding: "0px" }}>{(review) ? item?.pensionContribution : <input value={item?.pensionContribution} onChange={(e) => handlePensionContribution(item?.staffId, e?.target?.value)} type="number" className="tableInput" />}</td>
                  <td style={{ padding: "0px" }}>{(review) ? item?.healthInsuranceDeductions : <input value={item?.healthInsuranceDeductions} onChange={(e) => handleHealthInsuranceDeductions(item?.staffId, e?.target?.value)} type="number" className="tableInput" />}</td>
                  <td style={{ padding: "0px" }}>{(review) ? item?.arrears : <input value={item?.arrears} onChange={(e) => handleArrears(item?.staffId, e?.target?.value)} type="number" className="tableInput" />}</td>
                  <td style={{ padding: "0px" }}>{(review) ? item?.otherBonus : <input value={item?.otherBonus} onChange={(e) => handleOtherBonus(item?.staffId, e?.target?.value)} type="number" className="tableInput" />}</td>
                  <td style={{ padding: "0px" }}>{(review) ? item?.ltg : <input value={item?.ltg} onChange={(e) => handleLtg(item?.staffId, e?.target?.value)} type="number" className="tableInput" />}</td>
                  <td style={{ padding: "0px" }}>{(review) ? item?.otherDeductions : <input value={item?.otherDeductions} onChange={(e) => handleOthers(item?.staffId, e?.target?.value)} type="number" className="tableInput" />}</td>
                  {/* {showOthers && <td style={{ padding: "0px" }}>{(review) ? item?.otherDeductions : <input value={item?.otherDeductions} onChange={(e) => handleOthers(index, e?.target?.value)} type="number" className="tableInput" />}</td>}
                                {show13th && <td style={{ padding: "0px" }}>{(review) ? item?.thirteenthMonth : <input value={item?.thirteenthMonth} onChange={(e) => handle13th(index, e?.target?.value)} type="number" className="tableInput" />}</td>}
                                {showCoop && <td style={{ padding: "0px" }}>{(review) ? item?.cooperativeDeductions : <input value={item?.cooperativeDeductions} onChange={(e) => handleCoop(index, e?.target?.value)} type="number" className="tableInput" />}</td>} */}
                  {removeDuplicates(item?.bonus, "deductionId")?.sort((a,b) => (a.deductionItem.localeCompare(b.deductionItem))).map((additionalColumn, idx) => (
                    <td>
                      {
                        !review ? <input
                          disabled
                          value={additionalColumn?.amount}
                          onChange={(e) =>
                            handleNet(item?.staffId, additionalColumn, e?.target?.value)
                          }
                          type="number"
                          className="tableInput w-100"
                        /> : additionalColumn?.amount}
                    </td>
                  ))}

                  {removeDuplicates(item?.staffDeductions, "deductionId")?.sort((a,b) => (a.deductionItem.localeCompare(b.deductionItem))).map((additionalColumn, idx) => (
                    <td>
                      {!review ? <input
                        // disabled
                        value={additionalColumn?.amount}
                        onChange={(e) =>
                          handleNet(item?.staffId, additionalColumn, e?.target?.value)
                        }
                        type="number"
                        className="tableInput w-100"
                      /> : additionalColumn?.amount}
                    </td>
                  ))}

                  <td>{`₦${numberWithCommas(
                    parseFloat(stripCommas(
                      (Number(item?.baseSalary || 0) +
                        Number(item?.totalAllowances || 0) +
                        Number(item?.arrears || 0) +
                        Number(item?.ltg || 0) +
                        Number(item?.otherBonus || 0) +
                        Number(item?.thirteenthMonth || 0) -
                        (Number(item?.tax || 0) +
                          Number(item?.pensionContribution || 0) +
                          Number(item?.otherDeductions || 0) +
                          Number(item?.healthInsuranceDeductions || 0) +
                          Number(item?.cooperativeDeductions || 0))
                      )) + parseFloat(item?.bonus?.reduce((acc, item) => acc + item.amount, 0)) - parseFloat(item?.staffDeductions?.reduce((acc, item) => acc + item.amount, 0))).toFixed(2)
                  )}`}</td>
                  <td>
                    <img
                      onClick={() => handleShowModal(index, item?.staffName)}
                      className="pointer"
                      src={notes}
                      alt=""
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>



      <h3 className="m-t-20">Total Sum Statistics:</h3>

      <div className="w-100 scrollable">
        <table id={tableId} className="bordered-table">
          <thead className="border-top-none">
            <tr className="border-top-none">
              <th>
                Base Salary
              </th>
              <th>
                Total Allowances
              </th>
              <th>
                Tax
              </th>
              <th>
                Pension Contribution
              </th>
              <th>
                Health Insurance
              </th>
              <th>
                Arrears
              </th>
              <th>
                Other Bonuses
              </th>
              <th>
                LTG
              </th>
              <th>
                Other Deductions
              </th>
              {
                columnNames.sort().map((header) => (
                  <th className="w-30_ w-100 ">
                    <span className="">{header}</span>
                  </th>
                ))
              }
              <th>
                Net Pay
              </th>


              {/* {tableHeaders?.map((name) => (
                <th>{name}</th>
              ))} */}
            </tr>
          </thead>
          <tbody className="white-bg view-det-pane">
            <tr >
              <td>{`₦${numberWithCommas(parseFloat(tableData?.reduce((acc, item) => acc + item?.baseSalary, 0)).toFixed(2))}`}</td>
              <td>{`₦${numberWithCommas(parseFloat(tableData?.reduce((acc, item) => acc + item?.totalAllowances, 0)).toFixed(2))}`}</td>
              <td>{`₦${numberWithCommas(parseFloat(tableData?.reduce((acc, item) => acc + item?.tax, 0)).toFixed(2))}`}</td>
              <td>{`₦${numberWithCommas(parseFloat(tableData?.reduce((acc, item) => acc + item?.pensionContribution, 0)).toFixed(2))}`}</td>
              <td>{`₦${numberWithCommas(parseFloat(tableData?.reduce((acc, item) => acc + item?.healthInsuranceDeductions, 0)).toFixed(2))}`}</td>
              <td>{`₦${numberWithCommas(parseFloat(tableData?.reduce((acc, item) => acc + item?.arrears, 0)).toFixed(2))}`}</td>
              <td>{`₦${numberWithCommas(parseFloat(tableData?.reduce((acc, item) => acc + item?.otherBonus, 0)).toFixed(2))}`}</td>
              <td>{`₦${numberWithCommas(parseFloat(tableData?.reduce((acc, item) => acc + item?.ltg, 0)).toFixed(2))}`}</td>
              <td>{`₦${numberWithCommas(parseFloat(tableData?.reduce((acc, item) => acc + item?.otherDeductions, 0)).toFixed(2))}`}</td>
              {columnNames?.sort().map((item, idx) => {
               return <td>
                  {
                    sumValuesByType(tableData, item).toFixed(2) 
                  }
                </td>
              })}
              <td>{`₦${numberWithCommas(parseFloat(tableData?.reduce((acc, item) => acc + (Number(item?.baseSalary || 0) +
                        Number(item?.totalAllowances || 0) +
                        Number(item?.arrears || 0) +
                        Number(item?.ltg || 0) +
                        Number(item?.otherBonus || 0) +
                        Number(item?.thirteenthMonth || 0) -
                        (Number(item?.tax || 0) +
                          Number(item?.pensionContribution || 0) +
                          Number(item?.otherDeductions || 0) +
                          Number(item?.healthInsuranceDeductions || 0) +
                          Number(item?.cooperativeDeductions || 0))
                      ) + parseFloat(item?.bonus?.reduce((acc, item) => acc + item.amount, 0)) - parseFloat(item?.staffDeductions?.reduce((acc, item) => acc + item.amount, 0)), 0)).toFixed(2))}`}</td>
             
                   
                      



              {/* <td style={{ padding: "0px" }}>{(review) ? item?.tax : <input value={item?.tax} onChange={(e) => handleTax(item?.staffId, e?.target?.value)} type="number" className="tableInput" />}</td>
              <td style={{ padding: "0px" }}>{(review) ? item?.pensionContribution : <input value={item?.pensionContribution} onChange={(e) => handlePensionContribution(item?.staffId, e?.target?.value)} type="number" className="tableInput" />}</td>
              <td style={{ padding: "0px" }}>{(review) ? item?.healthInsuranceDeductions : <input value={item?.healthInsuranceDeductions} onChange={(e) => handleHealthInsuranceDeductions(item?.staffId, e?.target?.value)} type="number" className="tableInput" />}</td>
              <td style={{ padding: "0px" }}>{(review) ? item?.arrears : <input value={item?.arrears} onChange={(e) => handleArrears(item?.staffId, e?.target?.value)} type="number" className="tableInput" />}</td>
              <td style={{ padding: "0px" }}>{(review) ? item?.otherBonus : <input value={item?.otherBonus} onChange={(e) => handleOtherBonus(item?.staffId, e?.target?.value)} type="number" className="tableInput" />}</td>
              <td style={{ padding: "0px" }}>{(review) ? item?.ltg : <input value={item?.ltg} onChange={(e) => handleLtg(item?.staffId, e?.target?.value)} type="number" className="tableInput" />}</td>
              <td style={{ padding: "0px" }}>{(review) ? item?.otherDeductions : <input value={item?.otherDeductions} onChange={(e) => handleOthers(item?.staffId, e?.target?.value)} type="number" className="tableInput" />}</td> */}
              {/* {showOthers && <td style={{ padding: "0px" }}>{(review) ? item?.otherDeductions : <input value={item?.otherDeductions} onChange={(e) => handleOthers(index, e?.target?.value)} type="number" className="tableInput" />}</td>}
              {show13th && <td style={{ padding: "0px" }}>{(review) ? item?.thirteenthMonth : <input value={item?.thirteenthMonth} onChange={(e) => handle13th(index, e?.target?.value)} type="number" className="tableInput" />}</td>}
              {showCoop && <td style={{ padding: "0px" }}>{(review) ? item?.cooperativeDeductions : <input value={item?.cooperativeDeductions} onChange={(e) => handleCoop(index, e?.target?.value)} type="number" className="tableInput" />}</td>} */}
              {/*              
              {item?.bonus?.map((additionalColumn, idx) => (
                <td>
                  tableData?.map(()=>{

                  })
                </td>
              ))}

              {item?.staffDeductions?.map((additionalColumn, idx) => (
                <td>
                   {additionalColumn?.amount}
                </td>
              ))} */}

              {/* <td>{`₦${numberWithCommas(
                parseFloat(stripCommas(
                  (Number(item?.baseSalary || 0) +
                    Number(item?.totalAllowances || 0) +
                    Number(item?.arrears || 0) +
                    Number(item?.ltg || 0) +
                    Number(item?.otherBonus || 0) +
                    Number(item?.thirteenthMonth || 0) -
                    (Number(item?.tax || 0) +
                      Number(item?.pensionContribution || 0) +
                      Number(item?.otherDeductions || 0) +
                      Number(item?.healthInsuranceDeductions || 0) +
                      Number(item?.cooperativeDeductions || 0))
                  )) + parseFloat(item?.bonus?.reduce((acc, item) => acc + item.amount, 0)) - parseFloat(item?.staffDeductions?.reduce((acc, item) => acc + item.amount, 0))).toFixed(2)
              )}`}</td> */}

            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ComputationTable;
