import { setCharAt, timeAgo } from "../../utility/general";
import userAvatar from "../../assets/images/user-avater.png"

const ApprovalJourney = ({ approvers }) => {
    console.log(approvers)
    return (
        <div className="w-75 view-det-pane m-t-20">
            <div>
                <p className="white-bg p-20 view-det-pane"><strong>Approving Officers Comments</strong></p>
            </div>
            <div>
                <table className="no-bordered-table">
                    <thead className="border-top-none bot-bord">
                        <tr className="border-top-none bot-bord">
                            <th>Assigned Officers</th>
                            <th align="right">In-Tray</th>
                            <th align="right">Out-Tray</th>
                            <th width="40%" align="left">Comment</th>
                        </tr>
                    </thead>
                    <tbody >
                        {approvers?.map((item, index) => (
                            <tr>
                                <td>
                                    <div className="w-100 flex flex-v-center ">
                                        <img className="img-avatar" src={item?.approverProfilePicture || userAvatar} alt="" />
                                        
                                        <div className="m-l-5">
                                            <p className="m-b-10"><strong>{item?.approver}</strong></p>
                                            <p>{item?.role}</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p className="m-b-10">{new Date(item?.dateIn).toUTCString()}</p>
                                    <p>{timeAgo(new Date(item?.dateIn))}</p>
                                </td>
                                <td>
                                    <p className="m-b-10">{item?.dateOut? new Date(item?.dateIn).toUTCString(): "Awaiting"}</p>
                                    <p>{item.dateOut ? timeAgo(new Date(item?.dateOut)) : ""}</p>
                                </td>
                                <td className="p-20">
                                    <p className="m-b-10">
                                        {setCharAt(item?.approverComment,1,"")?.split("_").map((string, idx)=>(
                                            <p className="m-b-5"><b>{item.approver}</b>:{string}</p>
                                        ))}
                                    </p>
                                    {/* <button className="rounded-btn">Respond to comment</button> */}
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </div>
    )

}

export default ApprovalJourney;