import { useEffect, useState } from "react";
import tune from "../../../assets/svg/tune.svg";
import Card from "../../layouts/Card";
import FilterHeader from "../../layouts/FilterHeader";
import SalaryReviewTable from "../../layouts/SalaryReviewTable";
import { Link } from "react-router-dom";
import { get } from "../../../utility/fetch";
import DeductionTable from "../../layouts/DeductionTable";
import SalaryTemplateTable from "../../layouts/SalaryTemplateTable";
import SeasonalItemTable from "../../layouts/SeasonalItemTable";
const SalaryReviewManager = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deductions, setDeductions] = useState(null);
  const [bonus, setBonus] = useState(null);
  const [template, setTemplate] = useState(null);

  const getDeductionApprovals = async () => {
    try {
      const res = await get("/Deduction/Approver");
      if (res.data.length > 0) setDeductions(res.data);
      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  const getSeasonalApprovals = async () => {
    try {
      const res = await get("/Bonus/Approver");
      if (res.data.length > 0) setBonus(res.data);
      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  const getTemplatelApprovals = async () => {
    try {
      const res = await get("/SalaryStructure/Approver");
      if (res.data.length > 0) setTemplate(res.data);

      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDeductionApprovals();
    getSeasonalApprovals();
    getTemplatelApprovals();
  }, []);
  const data = [
    {
      subjects: "Approval of 2023 Edo State 13th Month Salary Bonus",
      initiatedBy: "Williams Humphrey",
      agency: "Ministry of Industries ",
      created: "10.10.2023",
      status: "Approved",
    },
    {
      subjects: "Update of Employers Pension Contribution",
      initiatedBy: "Rosemond Ogbekhilu",
      agency: "Ministry of Education",
      created: "10.10.2023",
      status: "Pending",
    },
    {
      subjects: "Edo Green House Manufacturing Plant Construction",
      initiatedBy: "Humprehy Agbakogba",
      agency: "Ministry of Agriculture",
      created: "10.10.2023",
      status: "Rejected",
    },
  ];

  return (
    <div className="w-100">
      {/* <div>
        <p>Good Day</p>
        <p>Osagie Osaigbowo; #73974y</p>
        <p>Ministry Of Education</p>
      </div> */}

      <div className="flex space-between">
        <div className="flex flex-v-center m-t-20">
          <img className="m-r-10" src={tune} alt="" />
          <span className="dash-text">Salary Review Manager</span>
        </div>
        {/* <Link to="/payroll/settings/salary-review-manager/initiate-request">
          <button className="btn p-20">+ Initiate Request</button>
        </Link> */}
      </div>
      <FilterHeader />

      {deductions && (
        <div className="m-t-20">
          <div className="m-t-10">
            <h4>Deduction Table</h4>
          </div>
          <DeductionTable tableData={deductions} isRequest={true} />
        </div>
      )}

      {bonus && (
        <div className="m-t-20">
          <div className="m-t-10">
            <h4>Seasonal Bonus Table</h4>
          </div>
          <SeasonalItemTable tableData={bonus} isRequest={true} />
        </div>
      )}

      {template && (
        <div className="m-t-20">
          <div className="m-t-10">
            <h4>Salary Template Table</h4>
          </div>
          <SalaryTemplateTable tableData={template} isRequest={true} />
        </div>
      )}
    </div>
  );
};
export default SalaryReviewManager;
