import tune from "../../../../assets/svg/tune.svg";
import upload from "../../../../assets/svg/upload.svg";
import TextareaInput from "../../../inputs/TextareaInput";
import TextInput from "../../../inputs/TextInput";
import Select from "react-select";
import { useState } from "react";
import { get, post, put } from "../../../../utility/fetch";
import { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import SeasonalItemTable from "../../../layouts/SeasonalItemTable";
import ApprovalJourney from "../../../layouts/ApprovalJourney";

const ApproveBonusRequest = () => {
  const { id } = useParams();

  const [formData, setFormData] = useState(null);
  const [approvers, setApprovers] = useState([
    {
      approverId: 0,
      priority: 0,
    },
  ]);
  const [bonus, setBonus] = useState([]);
  const [deductionSource, setDeductionSource] = useState([]);
  const [mdaUsers, setMdaUsers] = useState([]);
  const [invalid, setInvalid] = useState(false);
  const [data, setData] = useState(null);
  const [reviewData, setReviewData] = useState({
    comment: "",
    id: id,
  });
  const router = useHistory();

  const getBonuSources = async () => {
    try {
      const res = await get("/DeductionSource");

      setDeductionSource(
        res.data?.map((item) => ({
          label: item.description,
          value: item.id,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getValues = async () => {
    if (id !== "0") {
      try {
        const res = await get(`/Bonus/${id}`);
        console.log(res.data);
        setData(res.data);
        setFormData({
          subject: res.data.subject,
          comments: res.data.comment,
          deductionSourceId: "", // Initialize with an empty string
          deductionId: id,
          description: res.data.seasonalItem,
          percentage: res.data.percentage,
          amount: res.data.amount,
          period: res.data.period,
          calculatedFrom: res.data.calculatedFrom,
        });
      } catch (err) {
        setInvalid(true);
        console.log(err);
      }
    }
  };

  const submit = async () => {
    try {
      const res = await put("/Bonus/Approve", [reviewData]);
      toast.success(res.message);

      console.log(res);
      router.push("/payroll/settings/salary-review-manager");
    } catch (e) {
      toast.error(e.message);
      console.log(e);
    }
  };

  const reject = async () => {
    try {
      const res = await put("/Bonus/Reject", [reviewData]);
      toast.success(res.message);

      console.log(res);
      router.push("/payroll/settings/salary-review-manager");
    } catch (e) {
      toast.error(e.message);
      console.log(e);
    }
  };

  const review = async () => {
    try {
      const res = await put("/Bonus/Review", [reviewData]);
      toast.success(res.message);
      console.log(res);
      router.push("/payroll/settings/salary-review-manager");
    } catch (e) {
      toast.error(e.message);
      console.log(e);
    }
  };
  const getBonus = async () => {
    const res = await get("/Bonus");
    console.log(res);
    setBonus(res.data);
  };

  const getMdaUsers = async () => {
    const res = await get("/ModuleUsers");
    setMdaUsers(
      res.data?.map((item) => ({
        label: item.user,
        value: item.userId,
      }))
    );
    console.log(res);
  };

  const handleReviewChange = (e) => {
    const { name, value } = e.target;
    setReviewData({
      ...reviewData,
      [name]: value,
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setReviewData({
      ...reviewData,
      [name]: value,
    });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    getMdaUsers();
    getValues();
  }, []);

  useEffect(() => {
    getBonuSources();
    getBonus();
  }, []);

  return (
    <div className="w-100">
      {invalid ? (
        <h1>Record not found</h1>
      ) : (
        <div>
          {formData && (
            <div className="w-100 flex">
              <div className="w-60 p-20">
                <div className="flex flex-v-center space-between ">
                  <div className="flex space-between">
                    <div className="flex flex-v-center m-t-20">
                      <img className="m-r-10" src={tune} alt="" />
                      <span className="dash-text">Approve Request</span>
                    </div>
                  </div>
                  <div className="w-50">
                    <div className="flex space-between m-b-20"></div>
                  </div>
                </div>
                <div className="m-t-20">
                  <label>Subject</label>
                  <TextInput
                    disabled
                    className="pay-input "
                    placeholder="Subject"
                    id="subject"
                    name="subject"
                    onChange={handleChange}
                    value={formData.subject}
                  />
                </div>
                <div className="m-t-20 flex">
                  {/* <div className="w-50">
                    <Select
                      isDisabled
                      options={mdaUsers}
                      isMulti
                      value={mdaUsers.filter((user) =>
                        selectedMdaUserIds.includes(user.value)
                      )}
                      onChange={(values) => {
                        const userIds = values.map((value) => value.value);
                        setSelectedMdaUserIds(userIds);
                        setApprovers(
                          userIds.map((userId, index) => ({
                            approverId: userId,
                            priority: index + 1,
                          }))
                        );
                      }}
                      placeholder="Select Users"
                    />
                  </div> */}
                </div>
                <div className="flex space-between m-b-20">
                  <div className="w-75 m-r-10"></div>
                </div>

                <label>Initiator's Comment</label>
                <TextareaInput
                  disabled
                  className="w-100"
                  name="comments"
                  id="comments"
                  value={formData.comments}
                />

                <ApprovalJourney approvers={data.approvers} />

                <TextareaInput
                  className="w-100 m-t-20"
                  placeholder="Comment"
                  onChange={handleReviewChange}
                  name="comment"
                  id="comment"
                  value={reviewData.comment}
                />

                <div className="w-100 m-t-20">
                  {/* <button type="button" className="secondary-btn w-100 p-20 ">
                  Save Draft
                </button> */}
                  <button
                    type="button"
                    className="btn w-100 p-20 m-t-20"
                    onClick={submit}
                  >
                    Approve
                  </button>
                  <button
                    type="button"
                    className="btn w-100 p-20 m-t-20"
                    onClick={review}
                  >
                    Review
                  </button>
                  <button
                    type="button"
                    className="btn w-100 p-20 m-t-20"
                    onClick={reject}
                  >
                    Reject
                  </button>
                </div>
              </div>

              <div className="w-40">
                <div className="m-t-20">
                  <div className="max-w-400">
                    <div className="content m-t-20">
                      <form>
                        <div className="">
                          <div className="m-t-10">
                            {" "}
                            <label>Description</label>
                            <input
                              disabled
                              className="pay-input w-100"
                              type="text"
                              name="description"
                              required
                              value={formData.description}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="m-t-10">
                            {" "}
                            <label>Calculated From </label>
                            <input
                              disabled
                              className="pay-input w-100"
                              type="text"
                              name="calculatedFrom"
                              required
                              value={formData.calculatedFrom}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="m-t-10">
                            {" "}
                            <label>Deduction Percentage</label>
                            <input
                              disabled
                              className="pay-input w-100"
                              step="any"
                              min={0}
                              type="number"
                              name="percentage"
                              required
                              value={formData.percentage}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="m-t-10">
                            {" "}
                            <label>Period</label>
                            <input
                              disabled
                              className="pay-input w-100"
                              type="text"
                              name="period"
                              value={formData.period}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="m-t-10">
                            {" "}
                            <label>Amount</label>
                            <input
                              disabled
                              className="pay-input w-100"
                              type="number"
                              step="any"
                              min={0}
                              name="amount"
                              required
                              value={formData.amount}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ApproveBonusRequest;
