import React, { useState } from "react";
import trash from "../../assets/svg/trash.svg";
import { del, put } from "../../utility/fetch";
import { toast } from "react-hot-toast";
import ConfirmationModal from "./modals/ConfirmationModal";

const SalaryTemplateTable = ({
  tableData,
  selectedItem,
  setSelectedItem,
  isNew,
  isRequest,
  fetchData,
}) => {
  const [selectedId, setSelectedId] = useState("");
  const [confirmDel, setConfirmDel] = useState(false);
  const toggleConfirm = () => {
    setConfirmDel(!confirmDel);
  };
  const setId = (id) => {
    setSelectedId(id);
    toggleConfirm();
  };

  const submit = async (id) => {
    const res = await put("/SalaryStructure/submit", [id]);
    toast.success(res.message);
    fetchData();
    console.log(res);
  };
  const removeItem = async (id) => {
    try {
      const res = await del(`/SalaryStructure/${id}`, [id]);
      toggleConfirm();
      if (res.status === "success") {
        toast.success("Item deleted");
        fetchData();
      }
    } catch (err) {
      toast.error(err);
    }
  };
  const handleRowClick = (item) => {
    // Set the selected item when a row is clicked
    setSelectedItem(item);
  };

  const handleCheckboxChange = (item) => {
    // Toggle the selection status of the item
    if (selectedItem === item) {
      setSelectedItem(null);
    } else {
      setSelectedItem(item);
    }
  };

  return (
    <div className="w-100">
      <table className="bordered-table">
        <thead className="border-top-none">
          <tr className="border-top-none">
            <th>Select</th>
            <th>Steps</th>
            <th>Annual</th>
            <th>Monthly Basic Salary</th>
            <th>{"Rent (75%) "}</th>
            <th>{"Transport Allowanc(25%) "}</th>
            <th>Meal Subsidy</th>
            <th>{"Utility (30%) "}</th>
            <th>Domestic Allowance</th>
            <th>New Total</th>
            <th>LTG</th>
            {(isNew || isRequest) && <th>Action</th>}
          </tr>
        </thead>
        <tbody className="white-bg view-det-pane">
          {tableData?.map((item) => (
            <tr
              key={item.id}
              onClick={() => handleRowClick(item)}
              className={
                selectedItem === item ? "selected-row pointer" : "pointer"
              }
            >
              {}
              <td>
                <input
                  type="checkbox"
                  checked={selectedItem === item}
                  onChange={() => handleCheckboxChange(item)}
                />
              </td>
              <td>{item.step}</td>
              <td>{item.annualSalary}</td>
              <td>{item.monthlySalary}</td>
              <td>{item.rent}</td>
              <td>{item.transport}</td>
              <td>{item.mealSubsidy}</td>
              <td>{item.utility}</td>
              <td>{item.domesticServant}</td>
              <td>{item.newTotal}</td>
              <td>{item.ltg}</td>
              {isRequest ? (
                <td>
                  <a
                    href={`/payroll/settings/salary-review-manager/approve-template-request/${item.id}`}
                  >
                    <button className="btn">View Item</button>
                  </a>
                </td>
              ) : (
                <td>
                  {isNew && (
                    <div className="flex space-between">
                      <img
                        src={trash}
                        alt=""
                        className="m-r-20 pointer"
                        onClick={() => setId(item.id)}
                      />
                    </div>
                  )}
                </td>
              )}

              {item?.statusId < 2 && isNew && (
                <td>
                  <button className="btn" onClick={() => submit(item.id)}>
                    Confirm
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {confirmDel && (
        <ConfirmationModal
          cancel={() => toggleConfirm()}
          confirm={() => removeItem(selectedId)}
          message={"Are You sure You want to delete?"}
        />
      )}
    </div>
  );
};

export default SalaryTemplateTable;
