import tune from "../../../../assets/svg/tune.svg";
import upload from "../../../../assets/svg/upload.svg";
import TextareaInput from "../../../inputs/TextareaInput";
import TextInput from "../../../inputs/TextInput";
import DeductionTable from "../../../layouts/DeductionTable";
import Select from "react-select";
import { useState } from "react";
import { get, post } from "../../../../utility/fetch";
import { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const InitiateAddDeduction = () => {
  const { id } = useParams();

  const [formData, setFormData] = useState({
    subject: "",
    comment: "",
    deductionSourceId: "", // Initialize with an empty string
    deductionId: null,
    description: "",
    percentage: "",
    accountName: "",
    accountNo: "",
    bankName: "",
    amount: "",
  });
  const [mdas, setMdas] = useState([]);
  const [approvers, setApprovers] = useState([
    {
      approverId: 0,
      priority: 0,
    },
  ]);
  const [deductions, setDeductions] = useState([]);
  const [selectedMda, setSelectedMda] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deductionSource, setDeductionSource] = useState([]);
  const [mdaUsers, setMdaUsers] = useState([]);
  const [selectedMdaUserIds, setSelectedMdaUserIds] = useState([]);
  const [invalid, setInvalid] = useState(false);

  const getValues = async () => {
    if (id !== "0") {
      try {
        const res = await get(`/Deduction/Approved/${id}`);
        console.log("id", id);

        setFormData({
          subject: "",
          comment: "",
          deductionSourceId: "", // Initialize with an empty string
          deductionId: id,
          deductedFrom: res.data.deductedFrom,
          description: res.data.deductionItems,
          percentage: res.data.percentage,
          accountName: res.data.accountName,
          accountNo: res.data.accountNo,
          bankName: res.data.bankName,
          amount: res.data.amount,
        });
      } catch (err) {
        setInvalid(true);
        console.log(err);
      }
    }
  };

  const getDeductionSources = async () => {
    try {
      const res = await get("/DeductionSource");
      setDeductionSource(
        res.data?.map((item) => ({
          label: item.description,
          value: item.id,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const submit = async () => {
    const payload = [
      {
        ...formData,
        approvers: approvers, // Send it as an array, even if it has only one element
        approvalMemoUrl: ["string"],
      },
    ];

    console.log(payload);
    try {
      const res = await post("/Deduction", payload);
      toast.success(res.message);
      console.log(res);
      getDeductions();
      resetForm();
    } catch (e) {
      toast.error(e.message);
      console.log(e);
    }
  };

  const getDeductions = async () => {
    const res = await get("/Deduction");
    console.log(res);
    setDeductions(res.data);
  };

  const getMdaUsers = async () => {
    const res = await get(`/ModuleUsers`);
    setMdaUsers(
      res.data?.map((item) => ({
        label: item.user,
        value: item.userId,
      }))
    );
    console.log(res);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const resetForm = () => {
    setFormData({
      subject: "",
      comment: "",
      deductionSourceId: "",
      description: "",
      percentage: "",
      accountName: "",
      accountNo: "",
      bankName: "",
      deductedFrom: "",
      amount: "",
    });
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // Fetch deductionSourceId based on deductedFrom
    const selectedDeductionSource = deductionSource.find(
      (source) => source.label === formData.deductedFrom
    );

    if (selectedDeductionSource) {
      setFormData((prev) => ({
        ...prev,
        deductionSourceId: selectedDeductionSource.value,
      }));
    }
  }, []);

  useEffect(() => {
    getMdaUsers();
  }, []);

  useEffect(() => {
    getDeductionSources();
    getDeductions();
    getValues();
  }, []);

  return (
    <div className="w-100">
      {invalid ? (
        <h1>Record not found</h1>
      ) : (
        <div>
          <div className="w-100 flex">
            <div className="w-60 p-20">
              <div className="flex flex-v-center space-between ">
                <div className="flex space-between">
                  <div className="flex flex-v-center m-t-20">
                    <img className="m-r-10" src={tune} alt="" />
                    <span className="dash-text">Initiate Request</span>
                  </div>
                </div>
                <div className="w-50">
                  <div className="flex space-between m-b-20"></div>
                </div>
              </div>
              <TextInput
                className="pay-input m-t-20"
                placeholder="Subject"
                id="subject"
                name="subject"
                onChange={handleChange}
                value={formData.subject}
              />
              <div className="m-t-20 flex">
                <div className="w-50">
                  <Select
                    options={mdaUsers}
                    isMulti
                    value={mdaUsers.filter((user) =>
                      selectedMdaUserIds.includes(user.value)
                    )}
                    onChange={(values) => {
                      const userIds = values.map((value) => value.value);
                      setSelectedMdaUserIds(userIds);
                      setApprovers(
                        userIds.map((userId, index) => ({
                          approverId: userId,
                          priority: index + 1,
                        }))
                      );
                    }}
                    placeholder="Select Users"
                  />
                </div>
              </div>
              <div className="flex space-between m-b-20">
                <div className="w-75 m-r-10"></div>
              </div>

              <TextareaInput
                className="w-100 m-t-20"
                placeholder="Comment"
                onChange={handleChange}
                name="comment"
                id="comment"
                value={formData.comment}
              />

              <div className="w-100 m-t-20">
                {/* <button
              type="button"
              className="secondary-btn w-100 p-20 pointer"
              onClick={saveDraft}
            >
              Save Draft
            </button> */}
                <button
                  type="button"
                  className="btn w-100 p-20 m-t-20"
                  onClick={submit}
                >
                  Submit Request
                </button>
              </div>
            </div>

            <div className="w-40">
              <div className="m-t-20">
                <div className="max-w-400">
                  <div className="content m-t-20">
                    <form>
                      <div className="">
                        <div className="m-t-10">
                          {" "}
                          <label>Description</label>
                          <input
                            className="pay-input w-100"
                            type="text"
                            name="description"
                            required
                            value={formData.description}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="m-t-10">
                          {" "}
                          <label>Deduction Percentage</label>
                          <input
                            className="pay-input w-100"
                            step="any"
                            min={0}
                            type="number"
                            name="percentage"
                            required
                            value={formData.percentage}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="m-t-10">
                          {" "}
                          <label>Account Name</label>
                          <input
                            className="pay-input w-100 "
                            type="text"
                            name="accountName"
                            value={formData.accountName}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="m-t-10">
                          {" "}
                          <label>Account Number</label>
                          <input
                            className="pay-input w-100"
                            type="number"
                            name="accountNo"
                            value={formData.accountNo}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="m-t-10">
                          {" "}
                          <label>Bank Name</label>
                          <input
                            className="pay-input w-100"
                            type="text"
                            name="bankName"
                            value={formData.bankName}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="m-t-10">
                          {" "}
                          <label>Amount</label>
                          <input
                            className="pay-input w-100"
                            type="number"
                            step="any"
                            min={0}
                            name="amount"
                            required
                            value={formData.amount}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="w-100 m-t-20">
                          <Select
                            options={deductionSource}
                            required
                            placeholder="Select Deduction Source"
                            value={deductionSource?.find(
                              (source) =>
                                source.value === formData.deductionSourceId
                            )}
                            onChange={(value) =>
                              setFormData((prev) => ({
                                ...prev,
                                deductionSourceId: value?.value,
                              }))
                            }
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DeductionTable
            tableData={deductions}
            fetchData={getDeductions}
            deductionSource={deductionSource}
            isNew={true}
          />
        </div>
      )}
    </div>
  );
};

export default InitiateAddDeduction;
