import tune from "../../../../assets/svg/tune.svg";
import upload from "../../../../assets/svg/upload.svg";
import TextareaInput from "../../../inputs/TextareaInput";
import TextInput from "../../../inputs/TextInput";
import Select from "react-select";
import { useState } from "react";
import { get, post } from "../../../../utility/fetch";
import { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import SalaryTemplateTable from "../../../layouts/SalaryTemplateTable";

const InitiateAddSalaryTemplate = () => {
  const { id } = useParams();

  const [formData, setFormData] = useState({
    subject: "",
    comment: "",
    level: "",
    step: "",
    annualSalary: "",
    mealSubsidy: "",
    entertainment: "",
    amount: "",
    domesticServant: "",
    ltg: "",
  });
  const [mdas, setMdas] = useState([]);
  const [types, setTypes] = useState([]);
  const [approvers, setApprovers] = useState([
    {
      approverId: 0,
      priority: 0,
    },
  ]);
  const [salaryStructures, setSalaryStructures] = useState([]);
  const [selectedMda, setSelectedMda] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deductionSource, setDeductionSource] = useState([]);
  const [mdaUsers, setMdaUsers] = useState([]);
  const [selectedMdaUserIds, setSelectedMdaUserIds] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [invalid, setInvalid] = useState(false);

  const getStructureTypes = async () => {
    try {
      const res = await get("/StructureType");
      setTypes(
        res.data?.map((item) => ({
          label: item.structureType,
          value: item.id,
        }))
      );
    } catch (err) {}
  };

  const getLastNumber = (str) => {
    const matches = str.match(/\d+$/); // Match one or more digits at the end of the string
    return matches ? parseInt(matches[0]) : null; // Convert the matched string to an integer
  };
  const getValues = async () => {
    if (id !== "0") {
      try {
        const res = await get(`/SalaryStructure/Approved/${id}`);
        console.log(res.data);

        setFormData({
          subject: "",
          comment: "",
          deductionId: id,
          id: res.data.id,
          typeId: res.data.typeId,
          step: getLastNumber(res.data.step),
          level: getLastNumber(res.data.level),
          annualSalary: res.data.annualSalary,
          mealSubsidy: res.data.mealSubsidy,
          entertainment: res.data.entertainment,
          amount: res.data.amount,
          domesticServant: res.data.domesticServant,
          ltg: res.data.ltg,
        });
      } catch (err) {
        setInvalid(true);
        console.log(err);
      }
    }
  };

  const submit = async () => {
    const payload = [
      {
        ...formData,
        approvers: approvers,
        approvalMemoUrl: ["string"],
      },
    ];

    console.log(payload);
    try {
      const res = await post("/SalaryStructure", payload);
      toast.success(res.message);
      console.log(res);
      getSalaryStructures();
      resetForm();
    } catch (e) {
      toast.error(e.message);
      console.log(e);
    }
  };

  const getSalaryStructures = async () => {
    const res = await get("/SalaryStructure");
    console.log(res);
    setSalaryStructures(res.data);
  };

  const getMdaUsers = async () => {
    const res = await get(`/ModuleUsers`);
    setMdaUsers(
      res.data?.map((item) => ({
        label: item.user,
        value: item.userId,
      }))
    );
    console.log(res);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const resetForm = () => {
    setFormData({
      subject: "",
      comment: "",
      level: "",
      step: "",
      annualSalary: "",
      mealSubsidy: "",
      entertainment: "",
      amount: "",
      domesticServant: "",
      ltg: "",
    });
  };

  useEffect(() => {
    getMdaUsers();
    getValues();
  }, []);

  useEffect(() => {
    getSalaryStructures();
    getStructureTypes();
  }, []);

  return (
    <div className="w-100">
      {invalid ? (
        <h1>Record not found</h1>
      ) : (
        <div>
          <div className="w-100 flex">
            <div className="w-60 p-20">
              <div className="flex flex-v-center space-between ">
                <div className="flex space-between">
                  <div className="flex flex-v-center m-t-20">
                    <img className="m-r-10" src={tune} alt="" />
                    <span className="dash-text">Initiate Request</span>
                  </div>
                </div>
                <div className="w-50">
                  <div className="flex space-between m-b-20"></div>
                </div>
              </div>
              <TextInput
                className="pay-input m-t-20"
                placeholder="Subject"
                id="subject"
                name="subject"
                onChange={handleChange}
                value={formData.subject}
              />
              <div className="m-t-20 flex">
                <div className="w-50">
                  <Select
                    options={mdaUsers}
                    isMulti
                    value={mdaUsers.filter((user) =>
                      selectedMdaUserIds.includes(user.value)
                    )}
                    onChange={(values) => {
                      const userIds = values.map((value) => value.value);
                      setSelectedMdaUserIds(userIds);
                      setApprovers(
                        userIds.map((userId, index) => ({
                          approverId: userId,
                          priority: index + 1,
                        }))
                      );
                    }}
                    placeholder="Select Users"
                  />
                </div>
              </div>
              <div className="flex space-between m-b-20">
                <div className="w-75 m-r-10"></div>
              </div>

              <TextareaInput
                className="w-100 m-t-20"
                placeholder="Comment"
                onChange={handleChange}
                name="comment"
                id="comment"
                value={formData.comment}
              />

              {/* <div className="w-50 m-t-20">
            <div className="m-t-20">
              <p>Attach Documents</p>
              <button
                type="button"
                className="btn rounded-pill p-20 w-100 m-t-20 flex flex-v-center space-between"
              >
                {" "}
                Upload Documents
                <img src={upload} alt="" />
              </button>
              <div className="w-100 m-t-20">
                <Select placeholder="Select From M-File" />
              </div>
            </div>
          </div> */}

              <div className="w-100 m-t-20">
                {/* <button type="button" className="secondary-btn w-100 p-20 ">
                  Save Draft
                </button> */}
                <button
                  type="button"
                  className="btn w-100 p-20 m-t-20"
                  onClick={submit}
                >
                  Submit Request
                </button>
              </div>
            </div>

            <div className="w-40">
              <div className="m-t-20">
                <div className="max-w-400">
                  <div className="content m-t-20">
                    <form>
                      <div className="">
                        <div className="w-100 m-t-10">
                          <label>Type</label>
                          <Select
                            options={types}
                            required
                            placeholder="Select Type"
                            onChange={(value) =>
                              setFormData((prev) => ({
                                ...prev,
                                typeId: value?.value,
                              }))
                            }
                          />
                        </div>

                        <div className="w-100 m-t-10">
                          <label>Level</label>
                          <input
                            min={0}
                            className="pay-input"
                            type="number"
                            name="level"
                            required
                            onChange={handleChange}
                            value={formData.level}
                          />
                        </div>

                        <div className="w-100 m-t-10">
                          <label>Step</label>
                          <input
                            min={0}
                            className="pay-input"
                            required
                            type="number"
                            name="step"
                            onChange={handleChange}
                            value={formData.step}
                          />
                        </div>

                        <div className="w-100 m-t-10">
                          <label>Annual Salary</label>
                          <input
                            min={0}
                            className="pay-input"
                            step="any"
                            type="number"
                            name="annualSalary"
                            required
                            onChange={handleChange}
                            value={formData.annualSalary}
                          />
                        </div>

                        <div className="w-100 m-t-10">
                          <label>Meal Subsidy</label>
                          <input
                            min={0}
                            className="pay-input"
                            type="number"
                            step="any"
                            name="mealSubsidy"
                            required
                            onChange={handleChange}
                            value={formData.mealSubsidy}
                          />
                        </div>

                        <div className="w-100 m-t-10">
                          <label>Entertainment</label>
                          <input
                            min={0}
                            className="pay-input"
                            type="number"
                            step="any"
                            name="entertainment"
                            required
                            onChange={handleChange}
                            value={formData.entertainment}
                          />
                        </div>

                        <div className="w-100 m-t-10">
                          <label>Domestic Servant</label>
                          <input
                            min={0}
                            className="pay-input"
                            type="number"
                            step="any"
                            name="domesticServant"
                            required
                            onChange={handleChange}
                            value={formData.domesticServant}
                          />
                        </div>

                        <div className="w-100 m-t-10">
                          <label>LTG</label>
                          <input
                            min={0}
                            className="pay-input"
                            type="number"
                            step="any"
                            name="ltg"
                            required
                            onChange={handleChange}
                            value={formData.ltg}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SalaryTemplateTable
            tableData={salaryStructures}
            isNew={true}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            fetchData={getSalaryStructures}
          />
        </div>
      )}
    </div>
  );
};

export default InitiateAddSalaryTemplate;
